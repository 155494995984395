import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { Grid, TextField, Typography } from '@mui/material';
import Navbar from '../../components/navbar';
import Button from '@mui/material/Button';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import api from '../../components/api';

export default function EditCustomer() {
    const { id } = useParams();
    const navigate = useNavigate();
    var Link = require('react-router-dom').Link

    const cancle =()=>{
        navigate("/Customer");
    };
    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "cust_id": id,
        });

     

        api.post("/getDatacustomer",{
            cust_id : id,
        })
            
            .then(result => {
                console.log(result.data[0]['cust_name'])
                setCustid(result.data[0]['cust_id'])
                setName(result.data[0]['cust_name'])
                setAge(result.data[0]['cust_age'])
                setGender(result.data[0]['cust_gender'])
                setWeight(result.data[0]['cust_weight'])
                setPhone(result.data[0]['cust_phone'])
                setAllergic(result.data[0]['cust_allergic'])
                setDisease(result.data[0]['cust_disease'])


            })
            .catch(error => console.log('error', error));


    }, [id])

    const handleSubmit = event => {
        event.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "cust_id": custid,
            "cust_name": name,
            "cust_age": age,
            "cust_gender": gender,
            "cust_weight": weight,
            "cust_phone": phone,
            "cust_allergic": allergic,
            "cust_disease": disease,
            'custid_update': id,
        });

  

        api.post("/update_customer",{
            cust_id: custid,
            cust_name: name,
            cust_age: age,
            cust_gender: gender,
            cust_weight: weight,
            cust_phone: phone,
            cust_allergic: allergic,
            cust_disease: disease,
            custid_update: id,

        })
            
            .then(result => {
                alert ('แก้ไขข้อมูลลูกค้าสำเร็จ')
                if (result.data['message'] == 'SUCCESS') {
                    navigate("/Customer");

                }

            })
            .catch(error => console.log('error', error));

    }
    const [custid, setCustid] = useState('');
    const [name, setName] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');
    const [weight, setWeight] = useState('');
    const [phone, setPhone] = useState('');
    const [allergic, setAllergic] = useState('');
    const [disease, setDisease] = useState('');

    return (
        <div>
            <Navbar />
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="sm" sx={{ p: 10 }}>
                    <Paper sx={{ p: 3, bgcolor: '#c8e6c9' }}>
                        <Typography variant="h6" gutterBottom component="div">
                            แก้ไขข้อมูลลูกค้า
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                '& > :not(style)': {
                                    m: 0,
                                    p: 3,
                                    maxWidth: "sm",
                                    maxheight: 'sm',

                                },
                            }}
                        >
                            <Paper variant="outlined" color="secondary" >

                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField id="custid" label="รหัสลูกค้า" variant="outlined"
                                                fullWidth required i
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                value={custid} />

                                        </Grid>

                                        <Grid item xs={12} >
                                            <TextField id="name" label="ชื่อ นามสกุล" variant="outlined"
                                                fullWidth required i
                                                onChange={(e) => setName(e.target.value)}
                                                value={name}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField id="age" label="อายุ" variant="outlined"
                                                inputProps={{inputMode: 'numeric', pattern:'[0-9]*'}}
                                                fullWidth required
                                                onChange={(e) => setAge(e.target.value)}
                                                value={age} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField id="gender" label="เพศ" variant="outlined"
                                                fullWidth required
                                                onChange={(e) => setGender(e.target.value)}
                                                value={gender} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField id="weight" label="น้ำหนัก" variant="outlined"
                                                inputProps={{inputMode: 'numeric', pattern:'[0-9]*'}}
                                                fullWidth required
                                                onChange={(e) => setWeight(e.target.value)}
                                                value={weight} />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField id="phone" label="เบอร์ติดต่อ" variant="outlined"
                                                inputProps={{ maxlength:"10",inputMode: 'numeric', pattern:'[0][0-9]*'}}
                                                fullWidth required
                                                onChange={(e) => setPhone(e.target.value)}
                                                value={phone} />
                                        </Grid>



                                        <Grid item xs={12} sm={6}>
                                            <TextField id="allergic" label="ประวัติแพ้ยา" variant="outlined"
                                                fullWidth required
                                                onChange={(e) => setAllergic(e.target.value)}
                                                value={allergic} />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField id="disease" label="โรคประจำตัว" variant="outlined"
                                                fullWidth required
                                                onChange={(e) => setDisease(e.target.value)}
                                                value={disease} />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                
                                    <Button variant="contained" color="error" fullWidth onClick={cancle} >ยกเลิก</Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button type="submit" variant="contained" color="success" fullWidth >บันทึก</Button>
                            </Grid>

                                    </Grid>
                                </form>
                            </Paper>
                        </Box>
            
                    </Paper>
                </Container>
            </React.Fragment>
        </div>
    );
}
