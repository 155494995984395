import React, { useState,useEffect } from "react"
import Navbar from './../../components/navbar';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Grid, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import api from "../../components/api";
import { format } from 'date-fns';
import { Today } from "@mui/icons-material";


export default function AddProductPage() {
  const [pname, setPname] = useState('');
  const [amount, setAmount] = useState('');
  const [invoice, setInvoice] = useState('');
  const [order, setOder] = useState('');
  const [supplier, setSupplier] = useState('');
  const [addrsSup, setAddrsSup] = useState('');
  const [producer, setProducer] = useState('');
  const [addrsPro, setAddrsPro] = useState('');
  const [adDate, setAdDate] = useState('');
  const [expDate, setExpDate] = useState('');
  const [status, setStatus] = useState('');
  const [changeStatus, setChangeStatus] = useState('');
  const [unit,setUnit]=useState('');
  const navigate = useNavigate();
  const [idproduct,setIdproduct]=useState([]);
  
  const getDateTime = () => {
    let tempDate = new Date();
    let date = tempDate.getDate() + '/' + (tempDate.getMonth()+1) + '/' + tempDate.getFullYear() ; 
    console.log(date)
    setAdDate(date)
    setChangeStatus(date)
  }

  useEffect(() => {
    Idproduct();
    getDateTime()

}, [])

const Idproduct = () => {
  api.get("/allproduct")
    .then(
      (result) => {
        setIdproduct(result.data);
        console.log(idproduct) 
      },
    )
}

  const cancle=()=>{
    navigate("/Product");
  };

  const addNoti =(productId)=>{
    var date = new Date(expDate);
    date.setDate(date.getDate() - 14)
    console.log(format(date, 'yyyy/MM/dd'))
    api.post("/insert_noti",{
      noti_id: "no_"+productId,
      noti_status: "wait",
      notipro_id: productId,
      noti_time: format(date, 'yyyy/MM/dd').toLocaleString(),
      
    } ).then(error => console.log('error', error));
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const idm = localStorage.getItem("idm");

     var numberId = 0;
    var productId = "";
    idproduct.forEach((id)=>{
      var idsplit =  id.product_id.split("T");
      if(numberId<parseInt(idsplit[1])){
        numberId = parseInt(idsplit[1]);
      }
  });
  console.log("numberId"+numberId)
  productId ="PT"+(numberId+1).toString();
  console.log("pid :"+productId)

    api.post("/insert_product",{
     
      product_id: productId,
      product_name: pname,
      product_amount: amount,
      product_invoice: invoice,
      product_order: order,
      product_supplier: supplier,
      product_addressSupplier: addrsSup,
      product_producer: producer,
      product_addressProducer: addrsPro,
      product_dateExpire: expDate,
      product_status: status,
      product_unit:unit,
      memb_idp: idm,
    } )
    .then(Response => {
      alert('เพิ่มสินค้าใหม่สำเร็จ')
        if (Response.data['message'] == 'SUCCESS') {
          addNoti(productId);
          console.log(expDate);
          navigate("/Product");
        }
    })
      .then(error => console.log('error', error));
  

   
  }
  const pickExpDate = (expDate) => {
    
    let Datenow = new Date();
    let today = Datenow.getFullYear() + '-' + (Datenow.getMonth()+1) + '-' + Datenow.getDate() ;
    console.log(expDate +" : "+today)
    if (expDate<today) {
      alert('ไม่สามารถเพิ่มสินค้าได้เนื่องจากวันหมดอายุน้อยกว่าปัจจุบัน')
      setExpDate("")
    } 
    else{
      setExpDate(expDate)
    }

  }
  return (
    <>
      {/* <Navbar /> */}
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="sm" sx={{ p: 5 }}>
        
        
          <Paper sx={{ p:3, bgcolor: '#c8e6c9' }}>
            <Typography variant="h6" gutterBottom component="div">
              เพิ่มสินค้าใหม่
            </Typography>
            <Box
              sx={{
                display: 'flex',
                '& > :not(style)': {
                  m: 0,
                  p: 3,
                  maxWidth: "sm",
                  maxheight: 'sm',

                },
              }}>
            <Paper variant="outlined" color="secondary" >
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {/* <Grid item xs={12} sm={4}>
                  <TextField id="pid" label="รหัสสินค้า" variant="outlined"
                    fullWidth required
                    onChange={(e) => setPid(e.target.value)} />
                </Grid> */}
                <Grid item xs={12} sm={8}>
                  <TextField id="pname" label="ชื่อสินค้า" variant="outlined"
                    fullWidth required
                    onChange={(e) => setPname(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField id="amount" label="จำนวนสินค้า" variant="outlined"
                    fullWidth required
                    onChange={(e) => setAmount(e.target.value)} />
                     
                </Grid>
                <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">หน่วยสินค้า</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="unit"
                      
                      label="หน่วยสินค้า"
                      onChange={(e) => setUnit(e.target.value)}

                    >
                      <MenuItem value={'ลัง'}>ลัง</MenuItem>
                      <MenuItem value={'กระปุก'}>กระปุก</MenuItem>
                      <MenuItem value={'ขวด'}>ขวด</MenuItem>
                      <MenuItem value={'แผง'}>แผง</MenuItem>
                      <MenuItem value={'โหล'}>โหล</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField id="invoice" label="เลขที่ใบส่งของ" variant="outlined"
                    fullWidth required
                    onChange={(e) => setInvoice(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField id="order" label="เลขที่ใบสั่งซื้อ" variant="outlined"
                    fullWidth required
                    onChange={(e) => setOder(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField id="supplier" label="ผู้จำหน่าย" variant="outlined"
                    fullWidth required
                    onChange={(e) => setSupplier(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                      id="addrsSup"
                      label="ที่อยู่ผู้จำหน่าย"
                      multiline
                      fullWidth required
                      rows={3}
                      onChange={(e) => setAddrsSup(e.target.value)}
                      value={addrsSup}/>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField id="producer" label="ผู้ผลิต" variant="outlined"
                    fullWidth required
                    onChange={(e) => setProducer(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="addrsPro"
                    label="ที่อยู่ผู้ผลิต"
                    multiline
                    fullWidth required
                    rows={3}
                    onChange={(e) => setAddrsPro(e.target.value)}
                    value={addrsPro}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField id="adDate"  variant="outlined"
                    fullWidth required
                    value={adDate}
                    helperText="วันที่เพิ่มลงคลัง"
                    InputProps={{
                      readOnly: true,
                  }} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField id="expDate" type="date" variant="outlined"
                    fullWidth required
                    helperText="วันหมดอายุ"
                    value={expDate}
                    onChange={(e) => {
                      pickExpDate(e.target.value)
                      
                    }} />
                </Grid>

                <Grid item xs={6}>

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">สถานะ</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="status"
                      value={status}
                      label="สถานะ"
                      onChange={(e) => setStatus(e.target.value)}

                    >
                      <MenuItem value={'คงคลัง'}>คงคลัง</MenuItem>
                      <MenuItem value={'ทำลายทิ้ง'}>ทำลายทิ้ง</MenuItem>
                      <MenuItem value={'หมดอายุ'}>หมดอายุ</MenuItem>
                      <MenuItem value={'เสื่อมสภาพ'}>เสื่อมสภาพ</MenuItem>

                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField id="changeStatus"  variant="outlined"
                    fullWidth required
                    value={changeStatus}
                    helperText="วันปรับสถานะ"
                    InputProps={{
                      readOnly: true,
                  }}
                    />
                </Grid>
                {/* <Grid item xs={12} sm={12}>
                  <TextField id="branch"  variant="outlined"
                    fullWidth required
                    label="สาขา"
                    onChange={(e) => setbranch(e.target.value)} />
                </Grid> */}

                <Grid item xs={6}>
                <Button variant="contained" color="error" fullWidth onClick={cancle}>
                  ยกเลิก</Button>
                </Grid>
                <Grid item xs={6}>
                  <Button type="submit" variant="contained" fullWidth color="success">บันทึก</Button>
                </Grid>
              </Grid>
            </form>
            </Paper>
            
            </Box>
          
          </Paper>
          
          
          
         
        </Container>
      </React.Fragment>
    </>
  )
}
