import React, { useState, useEffect } from "react"
import Navbar from './../../components/navbar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { Typography } from "@mui/material";
import ButtonGroup from '@mui/material/ButtonGroup';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { green } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import api from '../../components/api'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function StickyHeadTable() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [Idcusdelete, setCusdelete] =useState('');
    const [items, setItems] = useState([]);
    const [allcustomer, setAllCustomer] = useState([]);
    const [searched, setSearched] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const create_cutomer=()=>{
        navigate("/CreateCustomer");
    };

    const handleClickOpenDialog = id => {
        navigate("/DetailCustomer/" + id);
    };

    const handleClickOpen = id => {
        setCusdelete(id);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {

        CustomerGet()

    }, [])

    const CustomerGet = () => {

        api.get("/allcustomer")

            .then(
                (result) => {

                    //console.log(result[0].memb_id)
                    setItems(result.data);
                    setAllCustomer(result.data);
                },
            )
    }

    const UpdateUser = id => {
        navigate("/EditCustomer/" + id);
    }
    const Deletecustomer = id => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
       
        api.post("/delete_customer", {
            cust_id: id,
        })

            .then(result => {
                alert(result.data['message'])
                if (result.data['message'] == 'SUCCESS') {
                    CustomerGet()
                    setOpen(false);
                }

            })
            .catch(error => console.log('error', error));

    }

    const requestSearch = (searchedVal) => {
        if (searchedVal == "") {
            setItems(allcustomer)
        } else {
            setSearched(searchedVal)
            const filteredRows = allcustomer.filter((cust) => {
                return cust.cust_name.includes(searchedVal);

            });
            setItems(filteredRows);
        }

    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: green[300],
            color: theme.palette.common.black,
            fontSize: 18,

        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>
            
            <CssBaseline />
            <Container fixed sx={{ p: 5 }} >
                <Paper sx={{ p: 3, bgcolor: '#c8e6c9' }}>
                    <Box display="flex"   >
                        <Box sx={{ flexGrow: 1 }}>
                            <Typography variant="h4" gutterBottom component="div">
                                รายชื่อลูกค้า

                            </Typography>
                            <br />
                            <div >
                                <form >
                                    <label >
                                        ระบุชื่อลูกค้าที่ต้องการค้นหา: &nbsp;
                                        <input type="text" name="name" onChange={e => requestSearch(e.target.value)} />
                                    </label>

                                </form>
                            </div>
                            <br />
                        </Box>

                        <Box>
                           
                                <Button variant="contained" color='success' onClick={create_cutomer}>+ เพิ่มลูกค้าใหม่</Button>
                        </Box>
                    </Box>
                    <TableContainer component={Paper}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                {/* <StyledTableCell >รหัสลูกค้า</StyledTableCell > */}
                                    <StyledTableCell >ชื่อ นามสกุล</StyledTableCell >
                                    <StyledTableCell align="center">อายุ&nbsp;</StyledTableCell >
                                    <StyledTableCell align="center">เพศ&nbsp;</StyledTableCell >
                                    <StyledTableCell align="center">เบอร์ติดต่อ&nbsp;</StyledTableCell >
                                    <StyledTableCell align="center">เพิ่มเติม&nbsp;</StyledTableCell >

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.cust_id}>
                                                {/* <TableCell component="th" scope="row"onClick={() =>
                                            handleClickOpenDialog(row.cust_id)}>
                                            {row.cust_id}
                                        </TableCell> */}
                                        <TableCell onClick={() =>handleClickOpenDialog(row.cust_id)}>
                                            {row.cust_name}</TableCell>
                                        <TableCell align="center"onClick={() =>handleClickOpenDialog(row.cust_id)}>
                                            {row.cust_age}</TableCell>
                                        <TableCell align="center"onClick={() =>handleClickOpenDialog(row.cust_id)}>
                                            {row.cust_gender}</TableCell>
                                        <TableCell align="center"onClick={() =>handleClickOpenDialog(row.cust_id)}>
                                            {row.cust_phone}</TableCell>
                                       
                                        <TableCell align="center">
                                            <ButtonGroup variant="outlined" aria-label="outlined primary button group">

                                                <Button startIcon={<BorderColorIcon />} 
                                                    onClick={() => UpdateUser(row.cust_id)} color="success">แก้ไข
                                                </Button>
                                                
                                                
                                                <Button  startIcon={<DeleteIcon />} color="error" 
                                                    onClick={() => handleClickOpen (row.cust_id)}>
                                                    ลบ
                                                </Button>
                                                <Dialog
                                                  fullScreen={fullScreen}
                                                    open={open}
                                                    onClose={handleClose}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                >
                                                    <DialogTitle id="alert-dialog-title">
                                                        {"ต้องการลบหรือไม่"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="alert-dialog-description">
                                                           ข้อมูลของลูกค้ารายนี้จะถูกลบ
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={handleClose} variant="outlined"  color ='error'>ยกเลิก</Button>
                                                        <Button onClick={() => Deletecustomer(Idcusdelete)} 
                                                        autoFocus variant="contained" color='success'>
                                                            ยืนยัน
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                               

                                            </ButtonGroup>
                                        </TableCell>

                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={items.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Container>
        </>
    );
}
