import React, { useState, useEffect } from 'react';
import Navbar from '../../components/navbar';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { useParams } from 'react-router-dom';
import { Grid, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import api from '../../components/api';
import Autocomplete from '@mui/material/Autocomplete';

export default function EditAppointment() {
    const { id } = useParams();
    const navigate = useNavigate();
    const pharmacist_name = localStorage.getItem("pharmacist_name");
    const [items, setItems] = useState([]);
    const [productitem, setproductitem] = useState([]);
    
    const cancle=()=>{
        navigate("/Appointment");
    };

    const CustomerGet = () => {
        api.get("/appcustomer")
            .then(
                (result) => {
                    console.log(result.data);
                    setItems(result.data);
                },
            )
    }

    const ProductGet = () => {
        api.get("/appproduct")
            .then(
                (result) => {
                    console.log(result.data);
                    setproductitem(result.data);
                    
                },
            )
    }

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        CustomerGet();
        ProductGet();
        // setMembID(pharmacist_name);

        api.post("/getdata_appointment_join_name",{
            appointment_id : id,
        })
            
            .then(result => {
                console.log(result.data[0]['appointment_id'])
                setAppid(result.data[0]['appointment_id'])
                setPrintdate(result.data[0]['print_date'])
                setAppdate(result.data[0]['appointment_date'])
                setAppDetail(result.data[0]['details'])
                setMembID(result.data[0]['memb_name'])
                setCustid(result.data[0]['cust_id'])
                setProID(result.data[0]['product_id'])
                setcustgetname(result.data[0]['cust_name'])
                setprogetname(result.data[0]['product_name'])

            })
            .catch(error => console.log('error', error));

    }, [id])

    const handleSubmit = event => {
        event.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        console.log(id);
        console.log(membID);
        console.log(custID);
        console.log(proID);

        api.post("/update_appointment", {
            appointment_id: appid,
            print_date: printdate,
            appointment_date: appdate,
            app_details: appDetail,
            memb_idapp: localStorage.getItem("idm"),
            cust_idapp: custID,
            product_idapp: proID,
            appointment_idupdate: id,
            
        })
            
            .then(result => {
                alert ('อัพเดทข้อมูลสำเร็จ')
                if (result.data['message'] == 'SUCCESS') {

                    navigate("/appointment");
                }

            })
            .catch(error => console.log('error', error));
        api.post("/update_notitimeapm_status", {
            noti_status:'wait',
            notitime: appdate,
            noti_app_id: id


        })

    }
    const [appid, setAppid] = useState('');
    const [printdate, setPrintdate] = useState('');
    const [appdate, setAppdate] = useState('');
    const [appDetail, setAppDetail] = useState('');
    const [membID, setMembID] = useState('');
    const [custID, setCustid] = useState('');
    const [proID, setProID] = useState('');
    const [custgetname,setcustgetname]=useState('');
    const [progetname,setprogetname]=useState('');

    return(
        <>
        {/* <Navbar/> */}
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="sm" sx={{ p: 10 }}>
                <Paper sx={{ p: 3, bgcolor: '#c8e6c9' }}>
                <Typography variant="h6" gutterBottom component="div">
                    แก้ไขข้อมูลใบนัดหมาย</Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            '& > :not(style)': {
                                m: 0,
                                p: 3,
                                maxWidth: "sm",
                                maxheight: 'sm',
                                

                            },
                        }}
                    >
                        <Paper variant="outlined" color="secondary" >
                            
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TextField id="appid" label="รหัสใบนัดหมาย" variant="outlined"
                                            fullWidth required 
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            value={appid} />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                    <Autocomplete
                                                disablePortal
                                                // filterSelectedOptions
                                                id="custID"
                                                options={items.map((option) => option.cust_name)}
                                                value={custgetname}
                                                fullWidth required
                                                 onChange={(event, value)=>{
                                                     setcustgetname(value)
                                                    const filteredRows = items.filter((cus) => {
                                                                return cus.cust_name==value;});
                                                                const cid = filteredRows.map((option) => option.cust_id).toString()
                                                                setCustid(cid)
                                                                console.log(custID)
                                                }
                                                }
                                                renderInput={(params) => < TextField
                                                    {...params}
                                                    label="ลูกค้าที่นัดหมาย"
                                                    
                                                    
                                                    InputProps={{
                                                      ...params.InputProps,
                                                      type: 'search',
                                                    }}
                                                />}
                                            />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                    <Autocomplete
                                                disablePortal
                                                id="proID"
                                                options={productitem.map((option) => option.product_name)}
                                                fullWidth required
                                                value={progetname} 
                                                onChange={(event, value)=>{
                                                    setprogetname(value)
                                                    const updateproname = productitem.filter((product) => {
                                                        return product.product_name==value;});
                                                        const proid = updateproname.map((option) => option.product_id).toString()
                                                            setProID(proid)
                                                            console.log(proID)
                                                }}
                                                
                                              
                                               
                                                renderInput={(params) => < TextField
                                                    {...params}
                                                    label="ยาที่จะจ่าย"
                                                    InputProps={{
                                                      ...params.InputProps,
                                                      type: 'search',
                                                    }}
                                                />}
                                            />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            id="appDetail"
                                            label="รายละเอียด"
                                            multiline
                                            fullWidth required
                                            rows={4}
                                            onChange={(e) => setAppDetail(e.target.value)}
                                            value={appDetail}
                                            />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField id="printdate" type="date" variant="outlined"
                                                fullWidth required
                                                helperText="วันที่พิมพ์ใบนัดหมาย"
                                                onChange={(e) => setPrintdate(e.target.value)}
                                                value={printdate}
                                                />

                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField id="appdate" type="date" variant="outlined"
                                                fullWidth required
                                                helperText="วันที่นัดใบนัดหมาย"
                                                onChange={(e) => setAppdate(e.target.value)}
                                                value={appdate}
                                                />

                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <TextField id="membID" label="เภสัชกรผู้บันทึกข้อมูล" variant="outlined"
                                            fullWidth required
                                            helperText="ระบบจะบันทึกเภสัชกรที่เข้าสู่ระบบขณะนี้อัตโนมัติ"
                                            InputProps={{readOnly: true}}
                                            value={membID}
                                           />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                    <Button variant="contained" color="error" fullWidth onClick={cancle}>
                                    ยกเลิก</Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button type="submit" variant="contained" color="success" fullWidth >บันทึก</Button>
                        </Grid>


                                </Grid>
                            </form>
                        </Paper>
                    </Box>

                    </Paper>
                </Container>
            </React.Fragment>
        </>
    );
}