import React, { useState, useEffect } from "react";
import api from '../../components/api';
import { useParams } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Grid, TextField, Button } from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';


const theme = createTheme({
    palette: {
        primary: {
            main: green[400],
        },
        secondary: {
            main: '#4caf50',
        },
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog() {
    const [open, setOpen] = React.useState(false);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        handleClickOpen()
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

       api.post("/getDatapharmacist",{
            memb_id : id,

       })
           
            .then(result => {

                setID(result.data[0]['memb_username'])
                setName(result.data[0]['memb_name'])
                setEmail(result.data[0]['memb_email'])
                setPhone(result.data[0]['memb_phone'])
                setPosition(result.data[0]['memb_position'])
                setStatus(result.data[0]['memb_status'])

            })
            .catch(error => console.log('error', error));

    }, [id])
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        navigate("/Pharmacist");
    };

    const [membid, setID] = useState('');

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [position, setPosition] = useState('');
    const [status, setStatus] = React.useState('');

    return (

        <ThemeProvider theme={theme}>
            <div> <Dialog
                theme
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            ข้อมูลเภสัชกร
                        </Typography>

                    </Toolbar>
                </AppBar>

                <React.Fragment>
                    <CssBaseline />
                    <Container maxWidth="sm" sx={{ p: 10 }}>

                        <Paper sx={{ p: 3, bgcolor: '#c8e6c9' }}>
                            <Typography variant="h6" gutterBottom component="div">
                                รายละเอียดข้อมูลเภสัชกร
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    '& > :not(style)': {
                                        m: 0,
                                        p: 3,
                                        maxWidth: "sm",
                                        maxheight: 'sm',

                                    },
                                }}
                            >
                                <Paper variant="outlined" color="secondary" >



                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField id="membid" label="รหัสเภสัชกร" variant="standard"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                fullWidth required

                                                value={membid} />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField id="name" label="ชื่อ นามสกุล" variant="standard"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                fullWidth required
                                                value={name} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField id="email" label="Email" variant="standard"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                fullWidth required
                                                value={email} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField id="phone" label="เบอร์ติดต่อ" variant="standard"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                fullWidth required
                                                value={phone} />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField id="position" label="ตำแหน่ง" variant="standard"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                fullWidth required
                                                value={position} />

                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField id="status" label="สถานะ" variant="standard"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                fullWidth required
                                                value={status} />
                                        </Grid>
                                    </Grid>

                                </Paper>
                            </Box>
                        </Paper>
                    </Container>
                </React.Fragment>
            </Dialog>
            </div>
        </ThemeProvider>
    );
}