import React, { useState, useEffect,useRef } from "react";
import { useReactToPrint } from 'react-to-print';
import { useParams } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Grid, TextField, Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import api from '../../components/api';



// const ReactPdfPrint =()=> {
    
// }

const theme = createTheme({
    palette: {
        primary: {
            main: green[400],
        },
        secondary: {
            main: '#4caf50',
        },
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog() {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const componentRef = useRef();
    const handleprint = useReactToPrint({
         content: ()=> componentRef.current,
        documentTitle: 'ใบนัดหมาย'
        // onAfterPrint:()=> alert('print success')
    });
    useEffect(() => {
        handleClickOpen()
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        

      

        api.post("/getdata_appointment_join_name", {
            appointment_id : id,

        })
            
            .then(result => {
                
                setAppid(result.data[0]['appointment_id'])
                setPrintdate(result.data[0]['print_date'])
                setAppdate(result.data[0]['appointment_date'])
                setAppD(result.data[0]['details'])
                setMembID(result.data[0]['memb_name'])
                setCustid(result.data[0]['cust_name'])
                setProID(result.data[0]['product_name'])

            })
            .catch(error => console.log('error', error));


    }, [id])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        navigate("/Appointment");
    };

    const [appid, setAppid] = useState('');
    const [printdate, setPrintdate] = useState('');
    const [appdate, setAppdate] = useState('');
    const [appD, setAppD] = useState('');
    const [membID, setMembID] = useState('');
    const [custID, setCustid] = useState('');
    const [proID, setProID] = useState('');

    return (
        <ThemeProvider theme={theme}>
            <div> <Dialog
                theme
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            ข้อมูลใบนัดหมาย
                        </Typography>

                    </Toolbar>
                </AppBar>

                <React.Fragment>
                    <CssBaseline />
                    <Container maxWidth="sm" sx={{ p: 10 }}>
                    <Button onClick ={handleprint} variant="contained"> พิมพ์ใบนัด </Button> 
                    <Paper sx={{ p: 3 ,bgcolor: '#c8e6c9'}}>
                        <Box
                            sx={{
                                display: 'flex',
                                '& > :not(style)': {
                                    m: 0,
                                    p: 3,
                                    maxWidth: "sm",
                                    maxheight: 'sm',

                                },
                            }}>  
                            
                            <Paper variant="outlined" color="secondary"  ref={componentRef} style={{width:'100%', height: '100%'}} >
                            
                                
                                <div > 
                                
                                <Typography variant="h6" gutterBottom component="div">
                                    รายละเอียดข้อมูลใบนัดหมาย
                                </Typography>
                            
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={8}>
                                        <TextField id="appid" label="รหัสใบนัดหมาย" variant="standard"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth required
                                            value={appid} />
                                    </Grid>

                                    <Grid item xs={6} >
                                        <TextField id="printdate" label="วันที่พิมพ์ใบนัดหมาย" variant="standard"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth required
                                            value={printdate} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField id="appdate" label="วันที่นัดใบนัดหมาย" variant="standard"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth required
                                            value={appdate} />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>

                                        <TextField id="appD" label="รายละเอียด" variant="standard"
                                            multiline
                                            fullWidth required
                                            rows={4}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            value={appD} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField id="custID" label="ชื่อลูกค้าที่นัดหมาย" variant="standard"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth required
                                            value={custID} />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField id="proID" label="ยาที่จ่าย" variant="standard"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth required
                                            value={proID} />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField id="membID" label="เภสัชกรผู้บันทึกข้อมูล" variant="standard"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth required
                                            value={membID} />
                                    </Grid>

                                    
                                </Grid>
                                </div> 

                            </Paper>
                        </Box>
                        </Paper>
                    </Container>
                </React.Fragment>
            </Dialog>
            </div>
        </ThemeProvider>
    );
}