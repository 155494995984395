import React, { useState, useEffect, Component } from "react";
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { Grid, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import api from '../../components/api';
import Autocomplete from '@mui/material/Autocomplete';

export default function AddAppointment() {
    const [items, setItems] = useState([]);
    const [productitem, setproductitem] = useState([]);
    const navigate = useNavigate();
    const pharmacist_name = localStorage.getItem("pharmacist_name");
    const [idappm, setIdappm] = useState([]);

    const IDApp = () => {
        
        api.get("/allappointment")
          .then(
            (result) => {
                setIdappm(result.data);
                
            },
          )
    }

    const getDateTime = () => {
        let tempDate = new Date();
        let date = tempDate.getDate() + '/' + (tempDate.getMonth()+1) + '/' + tempDate.getFullYear() ; 
        setPrintdate(date)
    }

      const addNoti =(appmId)=>{
       console.log(appmId + 'Date '+appdate)
        api.post("/insert_notiapm",{
          notiapm_id: "no_"+appmId,
          noti_status: "wait",
          noti_app_id: appmId,
          notitime: appdate,
          
        } ).then(error => console.log('error', error));
      }
      
    const cancle = () => {
        navigate("/Appointment");
    };

    useEffect(() => {
        getDateTime();
        CustomerGet();
        ProductGet();
        IDApp();
        setMembID(pharmacist_name);
        console.log(IDApp) 
    }, [])

    const CustomerGet = () => {
        api.get("/appcustomer")
            .then(
                (result) => {
                    console.log(result.data);
                    setItems(result.data);  
                },
            )
    }

    const ProductGet = () => {

        api.get("/appproduct")

            .then(
                (result) => {

                    console.log(result.data);
                    setproductitem(result.data);     
                },
            )
    }

    const handleSubmit = event => {
        event.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const idm = localStorage.getItem("idm");

        var numberappId = 0;
        var appmId = "";
        idappm.forEach((id)=>{
            var idsplit =  id.appointment_id.split("p");
            if(numberappId<parseInt(idsplit[1])){
                numberappId = parseInt(idsplit[1]);
            }
        });
        console.log("numberId"+numberappId)
        appmId ="Ap"+(numberappId+1).toString();
        console.log("Apptid :"+appmId)
        console.log("proID :"+proID)
        console.log("custID :"+custID)
        console.log("idm :"+idm)
        

        api.post("/insert_appointment", {
            appointment_id: appmId,
            appointment_date: appdate,
            app_details: appD,
            memb_idapp: idm,
            cust_idapp: custID,
            product_idapp: proID,

        }).then(result => {
                alert ('เพิ่มใบนัดหมายสำเร็จ')
                if (result.data['message'] == 'SUCCESS') {
                    addNoti(appmId);
                    navigate("/appointment");
                }

            })
            .catch(error => console.log('error', error));

    }
    const [printdate, setPrintdate] = useState('');
    const [appdate, setAppdate] = useState('');
    const [appD, setAppD] = useState('');
    const [membID, setMembID] = useState('');
    const [custID, setCustid] = useState('');
    const [proID, setProID] = useState('');


    return (
        <>
            {/* <Navbar /> */}
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="sm" sx={{ p: 20 }}>
                    <Paper sx={{ p: 3, bgcolor: '#c8e6c9' }}>
                        <Typography variant="h6" gutterBottom component="div">
                            เพิ่มข้อมูลใบนัดหมาย</Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                '& > :not(style)': {
                                    m: 0,
                                    p: 3,
                                    maxWidth: "sm",
                                    maxheight: 'sm',


                                },
                            }}
                        >
                            <Paper variant="outlined" color="secondary" >

                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={3}>
                                        {/* <Grid item xs={12}>
                                            <TextField id="appid" label="รหัสใบนัดหมาย" variant="outlined"
                                                fullWidth required
                                                onChange={(e) => setAppid(e.target.value)}
                                            />
                                        </Grid> */}
                                        <Grid item xs={12} sm={12}>
                                            <Autocomplete
                                                disablePortal
                                                id="custID"
                                                options={items.map((option) => option.cust_name)}
                                                fullWidth required
                                                onChange={(event,value)=>{
                                                    console.log(value)
                                                    const filteredRows = items.filter((cus) => {
                                                        return cus.cust_name==value;});
                                                        const cid = filteredRows.map((option) => option.cust_id).toString()
                                                        setCustid(cid)
                                                        console.log(custID)
                                                   } }
                                                renderInput={(params) => < TextField
                                                    {...params}
                                                    label="ชื่อ-สกุล ลูกค้าที่จะนัดหมาย"
                                                    InputProps={{
                                                      ...params.InputProps,
                                                      type: 'search',
                                                    }}
                                                />}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                        <Autocomplete
                                                disablePortal
                                                id="proID"
                                                options={productitem.map((option) => option.product_name)}
                                                fullWidth required
                                               onChange={(event, value)=>{
                                                console.log(value)
                                                const filteredRows = productitem.filter((product) => {
                                                    return product.product_name==value;});
                                                    const proid = filteredRows.map((option) => option.product_id).toString()
                                                    setProID(proid)
                                                    console.log(proID)
                                               } }
                                                renderInput={(params) => < TextField
                                                    {...params}
                                                    label="ยาที่จะจ่าย"
                                                    InputProps={{
                                                      ...params.InputProps,
                                                      type: 'search',
                                                    }}
                                                />}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                id="appD"
                                                label="รายละเอียด"
                                                multiline
                                                fullWidth required
                                                rows={4}
                                                onChange={(e) => setAppD(e.target.value)}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField id="printdate"  variant="outlined"
                                             value={printdate}
                                                fullWidth required
                                                helperText="วันที่พิมพ์ใบนัดหมาย"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                // onChange={(e) => setPrintdate(e.target.value)}
                                            />

                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField id="appdate" type="date" variant="outlined"
                                                fullWidth required
                                                helperText="วันที่นัดหมาย"
                                                onChange={(e) => setAppdate(e.target.value)}
                                            />

                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <TextField id="membID" label="เภสัชกรผู้นัดหมาย" variant="outlined"
                                                fullWidth required
                                                value={membID}
                                                inputProps={{readOnly: true }}

                                                
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>

                                            <Button variant="contained" color="error" fullWidth onClick={cancle}>
                                                ยกเลิก</Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Button type="submit" variant="contained" color="success" fullWidth >บันทึก</Button>
                                        </Grid>


                                    </Grid>
                                </form>
                            </Paper>
                        </Box>

                    </Paper>
                </Container>
            </React.Fragment>
        </>
    )
}