import React, { useState, useEffect } from "react"
import Navbar from './../../components/navbar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { Typography } from "@mui/material";
import ButtonGroup from '@mui/material/ButtonGroup';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { green } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import api from '../../components/api'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


const Div = styled('div')(({ theme }) => ({
  ...theme.typography,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));


export default function StickyHeadTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [idappdelete, setIdAppdelete] = useState('');
  const [items, setItems] = useState([]);
  const [allappointment, setAllAppointment] = useState([]);
  const [searched, setSearched] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = React.useState('');
  const navigate = useNavigate();

  var tempDate = new Date();
  let  today =  tempDate.getFullYear()+ '-' + (tempDate.getMonth()+1) + '-' + tempDate.getDate() ; 
  const handlesubmitstatus = (id, status) =>{
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
   
    api.post("/update_statusappointment", {
      appointment_idupdate: id,
      appointment_id:id,
      appointment_status: status,
    }).then(result => {
      alert ('อัพเดทสถานะสำเร็จ')
      if (result.data['message'] == 'SUCCESS') {
        AppointmentGet()
        // setOpen(false);
      }
    })
    .catch(error => console.log('error', error));
  };

  const create_appiontment = () => {
    navigate("/AddAppointment");
  };

  const handleClickOpenDialog = id => {
    navigate("/DetailsAppointment/" + id);
  };

  const handleClickOpen = id => {
    setIdAppdelete(id)
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
console.log("today"+today)
    AppointmentGet()

  }, [])

  const AppointmentGet = () => {

    api.get("/get_appointment_join_name")

      .then(
        (result) => {

          //console.log(result[0].appointment_id)
          setItems(result.data);
          setAllAppointment(result.data);
        },
      )
  }

  const UpdateAppointment = id => {
    navigate("/EditAppointment/" + id);
  }
  const DeleteAppointment = id => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    api.post("/delete_appointment", {
      appointment_id: id,
    })

      .then(result => {
        alert ('ลบการนัดหมายสำเร็จ')
        if (result.data['message'] == 'SUCCESS') {
          AppointmentGet()
          setOpen(false);
        }

      })
      .catch(error => console.log('error', error));

  }

  const requestSearch = (searchedVal) => {
    console.log("search : "+searchedVal)
    if (searchedVal == "") {
      setItems(allappointment)
    } else {
      setSearched(searchedVal)
      const filteredRows = allappointment.filter((appointment) => {
        return appointment.appointment_date.includes(searchedVal);

      });
      setItems(filteredRows);
    }

  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: green[300],
      color: theme.palette.common.black,
      fontSize: 18,

    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Navbar />
      <CssBaseline />
      <Container maxWidth="lg" sx={{ p: 15 }}>
        <Paper sx={{ p: 3, bgcolor: '#c8e6c9' }}>
          <Box display="flex">
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom component="div">
                รายการใบนัดหมาย
              </Typography>
              <br />
              <div >
                <form >
                  <label >
                    ระบุวันที่นัดหมายที่ต้องการค้นหา: &nbsp;
                    <input type="date" name="name" onChange={e => requestSearch(e.target.value)} />

                  </label>
                  

                </form>
              </div>
              <br />
            </Box>
            <Box>
              <Button variant="contained" color='success' onClick={create_appiontment}>+ เพิ่มใบนัดหมายใหม่
              </Button>
            </Box>
          </Box>
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>รหัสใบนัดหมาย</StyledTableCell>
                  <StyledTableCell align="center">ลูกค้าที่นัดหมาย</StyledTableCell>
                  <StyledTableCell align="center">วันที่นัดหมาย</StyledTableCell>

                  <StyledTableCell align="center">เภสัชกรผู้บันทึกข้อมูล</StyledTableCell>
                  <StyledTableCell align="center">สถานะ</StyledTableCell>
                  <StyledTableCell align="center">เพิ่มเติม</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.appointment_id}>
                        <TableCell component="th" scope="row" onClick={() => handleClickOpenDialog(row.appointment_id)}>{row.appointment_id}</TableCell>
                        <TableCell align="center" onClick={() => handleClickOpenDialog(row.appointment_id)}>
                          {row.cust_name}
                        </TableCell>
                        <TableCell align="center" onClick={() => handleClickOpenDialog(row.appointment_id)}>
                          {row.appointment_date}
                        </TableCell>

                        <TableCell align="center" onClick={() => handleClickOpenDialog(row.appointment_id)}>
                          {row.memb_name}
                        </TableCell>

                        <TableCell >
                          {/* {row.appointment_date < today &&(
                           
                           "หมดเวลาการนัดหมาย"
                          )} */}
                          {row.status === 'wait'&&(
                            <FormControlLabel control={<Checkbox 
                              onClick={()=>
                                {
                                  
                                  handlesubmitstatus(row.appointment_id,'success')
                                  
                              }}
                              color="success"/>} label="ยังไม่สำเร็จ" />

                          )}
                          {row.status === 'success'&&(
                            <FormControlLabel control={<Checkbox 
                              onClick={()=>
                                {
                                  
                                  handlesubmitstatus(row.appointment_id,'wait')
                                  
                              }}
                              defaultChecked  color="success" />} 
                            label="สำเร็จ" />

                          )}
                          

                          
                        </TableCell>

                        <TableCell align="center">

                          <ButtonGroup variant="outlined" aria-label="outlined button group">
                            <Button startIcon={<BorderColorIcon />}
                              onClick={() => UpdateAppointment(row.appointment_id)} color='success'>แก้ไข</Button>
                            <Button startIcon={<DeleteIcon />} color="error" onClick={() => handleClickOpen(row.appointment_id)}>
                              ลบ
                            </Button>
                            <Dialog
                              open={open}
                              onClose={handleClose}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                            >
                              <DialogTitle id="alert-dialog-title">
                                {"ต้องการลบใบนัดหมายหรือไม่?"}
                              </DialogTitle>
                              <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                  ข้อมูลของใบนัดหมายรายการนี้จะถูกลบ
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={handleClose} variant="outlined" color='error'>ยกเลิก</Button>
                                <Button onClick={() => DeleteAppointment(idappdelete)} autoFocus variant="contained"
                                  color="success">
                                  ยืนยัน
                                </Button>
                              </DialogActions>
                            </Dialog>

                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={items.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Container>
    </>
  );
}

