import { useParams, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import api from '../../components/api'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { green } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Container,
  Paper
} from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: green[300],
    },
    secondary: {
      main: '#4caf50',
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    handleClickOpen()
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    console.log(id)

    api.post("/getDatapharmacist", {

      memb_id: id,
    })
      .then(result => {

        setUsername(result.data[0]['memb_username'])
        setName(result.data[0]['memb_name'])
        setEmail(result.data[0]['memb_email'])
        setPhone(result.data[0]['memb_phone'])
        setPosition(result.data[0]['memb_position'])
        setStatus(result.data[0]['memb_status'])


      })
      .catch(error => console.log('error', error));


  }, [id])
  const handleSubmit = event => {
    event.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");


    api.post("/update_profile", {
      memb_username: memb_username,
      memb_name: name,
      memb_email: email,
      memb_phone: phone,
      memb_position: position,
      memb_idupdate: id,

    })

      .then(result => {
        alert ('แก้ไขโปรไฟล์สำเร็จ')
        if (result.data['message'] == 'SUCCESS') {
          navigate("/Pharmacist");

        }

      })
      .catch(error => console.log('error', error));

  }
  const [memb_username, setUsername] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [position, setPosition] = useState('');
  const [status, setStatus] = useState('');


  const handleClickOpen = () => {
    setOpen(true);
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  const handleClose = () => {
    setOpen(false);
    navigate("/Product");
};

  return (
    <ThemeProvider theme={theme}>
      <div>

        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                โปรไฟล์
              </Typography>

            </Toolbar>
          </AppBar>

          <Paper sx={{ p: 5, bgcolor: '#c8e6c9' }}>
            <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
              <Container maxWidth="lg">
                <Typography sx={{ mb: 3 }} variant="h4">
                  ข้อมูลผู้ใช้งาน
                </Typography>
                <Grid container spacing={3}>
                  <Grid item lg={4} md={6} xs={12}>
                    <Card  sx={{ border: 2,borderColor: 'primary.main'}}>
                      <CardContent   >
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                           
                          }}
                        >
                          <Avatar
                            // src={user.avatar}
                            sx={{
                              height: 64,
                              mb: 2,
                              width: 64,
                              bgcolor: 'green'
                            }} />

                        </Box>
                        <Box sx={{ alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',color: 'action.active' }}>
                          <Badge color="secondary" variant="dot">
                            <Typography
                              color="textPrimary"
                              gutterBottom
                              variant="h5">
                              {status}
                            </Typography>


                          </Badge>
                        </Box>
                        
                      </CardContent>
                      <Divider />
                    </Card>
                  </Grid>
                  <Grid item lg={8} md={6} xs={12}>
                    <form onSubmit={handleSubmit}>
                      <Card sx={{ border: 2,borderColor: 'primary.main'}}>
                        <CardHeader title="รายละเอียด" />
                        <Divider />
                        <CardContent >
                          <Grid container spacing={3}>
                            <Grid item md={6} xs={12}>
                              <TextField id="memb_username" label="รหัสเภสัชกร" variant="outlined"
                                fullWidth required
                                onChange={(e) => setUsername(e.target.value)}
                                value={memb_username} />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <TextField id="name" label="ชื่อ นามสกุล" variant="outlined"
                                fullWidth required
                                onChange={(e) => setName(e.target.value)}
                                value={name}

                              />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <TextField id="email" label="Email" variant="outlined"
                                fullWidth required
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                              />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <TextField
                                id="phone" label="เบอร์ติดต่อ" variant="outlined"
                                inputProps={{ maxlength: "10", inputMode: 'numeric', pattern: '[0][0-9]*' }}
                                fullWidth required
                                onChange={(e) => setPhone(e.target.value)}
                                value={phone}
                              />
                            </Grid>
                            <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">ตำแหน่ง</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="position"
                                                    value={position}
                                                    label="ตำแหน่ง"
                                                    onChange={(e) => setPosition(e.target.value)}

                                                >
                                                    <MenuItem value={'อาจารย์'}>อาจารย์</MenuItem>
                                                    <MenuItem value={'นิสิตชั้นปีที่4'}>นิสิตชั้นปีที่4</MenuItem>
                                                    <MenuItem value={'นิสิตชั้นปีที่5'}>นิสิตชั้นปีที่5</MenuItem>
                                                    <MenuItem value={'นิสิตชั้นปีที่6'}>นิสิตชั้นปีที่6</MenuItem>


                                                </Select>
                                            </FormControl>
                            </Grid>
                          </Grid>
                        </CardContent>
                        <Divider />
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            p: 2
                          }}
                        >
                          <Button type="submit" color="success" variant="contained">
                            บันทึก
                          </Button>
                        </Box>
                      </Card>
                    </form>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Paper>

        </Dialog>
      </div>
    </ThemeProvider>
  );
}
