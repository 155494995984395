import React from 'react'
import LoginPage from './pages/Login/LoginPage';



function App() {

  
    return <LoginPage />
  
}

export default App;



