import React, { useState, useEffect } from "react"
import Navbar from './../../components/navbar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { Typography } from "@mui/material";
import ButtonGroup from '@mui/material/ButtonGroup';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { green } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import api from '../../components/api'


export default function StickyHeadTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [opendialog, setOpendialog] = React.useState(false);
  const [allproduct, setAllProduct] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [iddelete, setIdDelete] = useState('');
  const navigate = useNavigate();

  

  const create_product = () => {
    navigate("/AddProductPage");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen = id => {
    console.log(id);
    setIdDelete(id)
    setOpen(true);
  };
  const handleClickOpenDialog = id => {
    navigate("/DetailsProduct/" + id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [items, setItems] = useState([]);
  useEffect(() => {
    ProductGet();
  }, [])

  const ProductGet = () => {

    api.get('/allproduct'

    ).then(res => {
      console.log(res);
      // console.log(res.data);

      setItems(res.data);
      setAllProduct(res.data);
    })

  }

  const requestSearch = (searchedVal) => {
    if (searchedVal == "") {
      setItems(allproduct)
    } else {
      
      const filteredRows = allproduct.filter((product) => {
        return product.product_name.includes(searchedVal);

      });
      setItems(filteredRows);
    }
  }
  const requestSearchDate = (searchdate) => {
    if (searchdate == "") {
      setItems(allproduct)
    } else {
      
      const filteredRows = allproduct.filter((product) => {
        return product.product_dateExpire.includes(searchdate);

      });
      setItems(filteredRows);
    }
  }





  const DeleteProduct = id => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");


    console.log(id);
    api.post("/delete_product", {
      product_id: id,
    })
      .then(result => {
        alert(result.data['message'])
        if (result.data['message'] == 'SUCCESS') {
          ProductGet()
          setOpen(false);
        }

      })
      .catch(error => console.log('error', error));

  }
  const UpdateProduct = id => {
    navigate("/EditProduct/" + id);
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: green[300],
      color: theme.palette.common.black,
      fontSize: 18,

    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  return (
    <>
      <Navbar />
      <CssBaseline />
      <Container maxWidth="lg" sx={{ p: 5 }}>
        <Paper sx={{ p: 3, bgcolor: '#c8e6c9' }}>
          <Box display="flex">
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom component="div">
                รายการสินค้า
              </Typography>
              <br />
              <div >
                <form >
                  <label >
                    ระบุชื่อสินค้าที่ต้องการค้นหา: &nbsp;
                    <input type="text" name="name" onChange={e => requestSearch(e.target.value)} />
                  </label>

                </form>
              </div>
              <div >
                <form >
                  <label >
                    ระบุวันหมดอายุสินค้าที่ต้องการค้นหา: &nbsp;
                    <input type="date" name="name" onChange={e => requestSearchDate(e.target.value)} />
                  </label>

                </form>
              </div>

              <br />
            </Box>
            <Box>
              <Button variant="contained" color='success' onClick={create_product} >+ เพิ่มสินค้าใหม่ </Button>
            </Box>
          </Box>
          <Box sx={{ flexShrink: 0, ml: 2.5 }}>
          </Box>
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>

                  {/* <StyledTableCell>รหัสสินค้า</StyledTableCell> */}
                  <StyledTableCell >ชื่อสินค้า</StyledTableCell>
                  <StyledTableCell align="center">จำนวนสินค้า</StyledTableCell>
                  <StyledTableCell align="center">หน่วยสินค้า</StyledTableCell>
                  <StyledTableCell align="center">วันที่หมดอายุ</StyledTableCell>
                  <StyledTableCell align="center">เพิ่มเติม</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        key={row.product_id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        {/* <TableCell component="th" scope="row" onClick={() => handleClickOpenDialog(row.product_id)}>
                          {row.product_id}</TableCell> */}
                        <TableCell  onClick={() => handleClickOpenDialog(row.product_id)}>
                          {row.product_name}</TableCell>
                        <TableCell align="center" onClick={() => handleClickOpenDialog(row.product_id)}>
                          {row.product_amount}</TableCell>
                        <TableCell align="center" onClick={() => handleClickOpenDialog(row.product_id)}>
                          {row.product_unit}</TableCell>
                        <TableCell align="center" onClick={() => handleClickOpenDialog(row.product_id)}>
                          {row.product_dateExpire}  </TableCell>
                        <TableCell align="center">
                          <ButtonGroup variant="outlined" aria-label="outlined button group">
                            <Button startIcon={<BorderColorIcon />}
                              onClick={() => UpdateProduct(row.product_id)} color='success'>แก้ไข</Button>
                            <Button startIcon={<DeleteIcon />} color="error" onClick={() => handleClickOpen(row.product_id)}>
                              ลบ
                            </Button>
                            <Dialog
                              open={open}
                              onClose={handleClose}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                            >
                              <DialogTitle id="alert-dialog-title">
                                {"ต้องการลบสินค้าหรือไม่?"}
                              </DialogTitle>
                              <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                  ข้อมูลของสินค้ารายการนี้จะถูกลบ
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={handleClose} variant="outlined" color='error'>ยกเลิก</Button>
                                <Button onClick={() => DeleteProduct(iddelete)} variant="contained"
                                  color="success">
                                  ยืนยัน
                                </Button>
                              </DialogActions>
                            </Dialog>

                          </ButtonGroup>

                          <Dialog open={opendialog}></Dialog>


                        </TableCell>

                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 50, 100]}
            component="div"
            count={items.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Container>

    </>
  );
}
