import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Grid, TextField, Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import api from '../../components/api';

const theme = createTheme({
    palette: {
        primary: {
            main: green[400],
        },
        secondary: {
            main: '#4caf50',
        },
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog() {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    useEffect(() => {
        handleClickOpen()
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        

      

        api.post("/getDatacustomer", {
            cust_id : id,

        })
            
            .then(result => {
                
                setCustid(result.data[0]['cust_id'])
                setName(result.data[0]['cust_name'])
                setAge(result.data[0]['cust_age'])
                setGender(result.data[0]['cust_gender'])
                setWeight(result.data[0]['cust_weight'])
                setPhone(result.data[0]['cust_phone'])
                setAllergic(result.data[0]['cust_allergic'])
                setDisease(result.data[0]['cust_disease'])
                setSymptom(result.data[0]['cust_symptom'])
                setMedicinelast(result.data[0]['cust_medicinelast'])


            })
            .catch(error => console.log('error', error));


    }, [id])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        navigate("/Customer");
    };

    const [custid, setCustid] = useState('');
    const [name, setName] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');
    const [weight, setWeight] = useState('');
    const [phone, setPhone] = useState('');
    const [symptom, setSymptom] = useState('');
    const [allergic, setAllergic] = useState('');
    const [disease, setDisease] = useState('');
    const [medicinelast, setMedicinelast] = useState('');

    return (
        <ThemeProvider theme={theme}>
            <div> <Dialog
                theme
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            รายชื่อลูกค้า
                        </Typography>

                    </Toolbar>
                </AppBar>

                <React.Fragment>
                    <CssBaseline />
                    <Container maxWidth="sm" sx={{ p: 10 }}>
                    <Paper sx={{ p: 3 ,bgcolor: '#c8e6c9'}}>
                        <Box
                            sx={{
                                display: 'flex',
                                '& > :not(style)': {
                                    m: 0,
                                    p: 3,
                                    maxWidth: "sm",
                                    maxheight: 'sm',

                                },
                            }}>
                            <Paper variant="outlined" color="secondary" >
                                <Typography variant="h6" gutterBottom component="div">
                                    รายละเอียดข้อมูลลูกค้า
                                </Typography>

                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField id="custid" label="รหัสลูกค้า" variant="standard"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth required
                                            value={custid} />
                                    </Grid>

                                    <Grid item xs={12} >
                                        <TextField id="name" label="ชื่อ นามสกุล" variant="standard"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth required
                                            value={name} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField id="age" label="อายุ" variant="standard"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth required
                                            value={age} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>

                                        <TextField id="gender" label="เพศ" variant="standard"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth required
                                            value={gender} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField id="weight" label="น้ำหนัก" variant="standard"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth required
                                            value={weight} />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField id="phone" label="เบอร์ติดต่อ" variant="standard"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth required
                                            value={phone} />
                                    </Grid>

                                    <Grid item xs={12} >
                                        <TextField id="symptom" label="อาการล่าสุด" variant="standard"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth required
                                            multiline
                                            rows={3}
                                            value={symptom} />
                                    </Grid>

                                    <Grid item xs={12} >
                                        <TextField id="allergic" label="ประวัติแพ้ยา" variant="standard"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth required
                                            multiline
                                            rows={3}
                                            value={allergic} />
                                    </Grid>

                                    <Grid item xs={12} >
                                        <TextField id="disease" label="โรคประจำตัว" variant="standard"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth required
                                            multiline
                                            rows={3}
                                            value={disease} />
                                    </Grid>

                                    <Grid item xs={12} >
                                        <TextField id="medicinelast" label="ยาที่รับล่าสุด" variant="standard"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth required
                                            multiline
                                            rows={3}
                                            value={medicinelast} />
                                    </Grid>



                                </Grid>

                            </Paper>
                        </Box>
                        </Paper>
                    </Container>
                </React.Fragment>
            </Dialog>
            </div>
        </ThemeProvider>
    );
}