import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
// import "./styles.css";
import './index.css';
import App from './App';
import { BrowserRouter , Routes , Route ,Switch,Link} from "react-router-dom";
import Customer from './pages/Customer/Customer';
import About from './pages/Pharmacist/AboutPage';
import CreatePharmacist from './pages/Pharmacist/create_pharmacist';
import Editpharmacist from './pages/Pharmacist/edit_pharmacist';
import Product from './pages/Product/ProductPage';
import Appointment from './pages/Appointment/appointment';
import AddProductPage from './pages/Product/AddProductPage';
import CreateCustomer from './pages/Customer/create_customer';
import EditCustomer from './pages/Customer/edit_customer';
import EditProduct from './pages/Product/edit_product';
import DetailsProduct from './pages/Product/Detail_Product';
import DetailsCustomer from './pages/Customer/Detail_Customer';
import DetailPharmacist from './pages/Pharmacist/Detail_Pharmacist';
import EditAppointment from './pages/Appointment/edit_appointment';
import AddAppointment from './pages/Appointment/create_appiontment';
import DetailsAppointment from './pages/Appointment/Detail_Appiontment';
import QRcodepage from './pages/Product/QRCode';
import ProfilePage from './pages/Pharmacist/Profile';
import ChangPassPage from './pages/Pharmacist/changPass';



ReactDOM.render(
 
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App/>} />
      
      <Route path="/Pharmacist" element={<About/>} />
      <Route path="/Create" element={<CreatePharmacist/>} />
      <Route path="/Edit/:id" element={<Editpharmacist/>} />
      <Route path="/DetailPharmacist/:id" element={<DetailPharmacist/>} />
      <Route path="/Profile/:id" element={<ProfilePage/>} />
      <Route path="/ChangPass/:id" element={<ChangPassPage/>} />
      
     
      
      
      <Route path="/Product" element={<Product/>} />
      <Route path="/AddProductPage" element={<AddProductPage/>} />
      <Route path="/EditProduct/:id" element={<EditProduct/>} />
      <Route path="/DetailsProduct/:id" element={<DetailsProduct/>} />
      <Route path="/QRCode/:id" element={<QRcodepage/>} />


      <Route path="/Customer" element={<Customer/>} />
      <Route path="/EditCustomer/:id" element={<EditCustomer/>} />
      <Route path="/CreateCustomer" element={<CreateCustomer/>} />
      <Route path="/DetailCustomer/:id" element={<DetailsCustomer/>} />
      
      <Route path="/Appointment" element={<Appointment/>}/>
      <Route path="/AddAppointment" element={<AddAppointment/>} />
      <Route path="/EditAppointment/:id" element={<EditAppointment/>} />
      <Route path="/DetailsAppointment/:id" element={<DetailsAppointment/>} />
    </Routes>
    
    
  </BrowserRouter>,
  document.getElementById('root')

  
);




