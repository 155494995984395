import React, { useState, useEffect } from 'react';
import api from '../../components/api';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { Grid, TextField, Typography } from '@mui/material';
import NavbarAdmin from '../../components/navbar_admin';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { green } from '@mui/material/colors';

export default function Createpharmacist() {
    const [membid, setID] = useState('');
    const [username, setUsername] = useState('');
    const [pass, setPassword] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [position, setPosition] = useState('');
    const navigate = useNavigate();
    const colorg = green[900];

    const cancle = () => {
        navigate("/Pharmacist");
    };

    useEffect(() => {
        Idmember();
    
    }, [])
    const Idmember = () => {
        api.get("/allpharmacist")
          .then(
            (result) => {
              setID(result.data);
              
            },
          )
      }

    const handleSubmit = event => {
        event.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var numberId = 0;
        var memberId = "";
        membid.forEach((id)=>{
        var idsplit =  id.memb_id.split("c");
        if(numberId<parseInt(idsplit[1])){
        numberId = parseInt(idsplit[1]);
        }
        });
        console.log("numberId"+numberId)
        memberId ="pmc"+(numberId+1).toString();
        console.log("membid :"+memberId)

        api.post('/check_pmc', {
            username: username,
            memb_name: name,
          }).then(response => {
            if (response.data == "ID is already"){
                alert('รหัสผู้ใช้งานนี้มีอยู่แล้ว')
            }
            else if(response.data == "Name is already"){
                alert('ชื่อ-สกุล ผู้ใช้งานนี้มีอยู่แล้ว')
            }
            else{ api.post("/insert_pharmacist", {
                memb_id: memberId,
                memb_username : username,
                memb_pass: pass,
                memb_name: name,
                memb_email: email,
                memb_phone: phone,
                memb_position: position,
                memb_status: 'active',
                memb_type: 'pharmacist',
    
            })
                
                .then(result => {
                    alert ('เพิ่มเภสัชกรสำเร็จ')
                    if (result.data['message'] == 'SUCCESS') {
    
                        navigate("/Pharmacist");
                    }
    
                })
                .catch(error => console.log('error', error));
            }

            })

        }

    return (
        <div>
            <NavbarAdmin />,
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="sm" sx={{ p: 10 }}>
                    <Paper sx={{ p: 3, bgcolor: '#c8e6c9' }}>
                        <Typography variant="h6" gutterBottom component="div" color={colorg} >
                            เพิ่มข้อมูลเภสัชกรใหม่
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                '& > :not(style)': {
                                    m: 0,
                                    p: 3,
                                    maxWidth: "sm",
                                    maxheight: 'sm',

                                },
                            }}
                        >
                            <Paper variant="outlined" color="secondary" >

                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField id="username" label="รหัสเภสัชกร" variant="outlined"
                                                fullWidth required i
                                                onChange={(e) => setUsername(e.target.value)} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField id="pass" label="รหัสผ่าน" variant="outlined"
                                                fullWidth required
                                                onChange={(e) => setPassword(e.target.value)} />
                                        </Grid>
                                        <Grid item xs={12} >
                                            <TextField id="name" label="ชื่อ นามสกุล" variant="outlined"
                                                fullWidth required
                                                onChange={(e) => setName(e.target.value)} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField id="email" label="Email" variant="outlined"
                                                fullWidth required
                                                onChange={(e) => setEmail(e.target.value)} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField id="phone" label="เบอร์ติดต่อ" variant="outlined"
                                                fullWidth required
                                                inputProps={{ maxlength:"10",inputMode: 'numeric', pattern:'[0][0-9]*'}}
                                                onChange={(e) => setPhone(e.target.value)} />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>

                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">ตำแหน่ง</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="position"
                                                    value={position}
                                                    label="ตำแหน่ง"
                                                    onChange={(e) => setPosition(e.target.value)}

                                                >
                                                    <MenuItem value={'อาจารย์'}>อาจารย์</MenuItem>
                                                    <MenuItem value={'นิสิตชั้นปีที่4'}>นิสิตชั้นปีที่4</MenuItem>
                                                    <MenuItem value={'นิสิตชั้นปีที่5'}>นิสิตชั้นปีที่5</MenuItem>
                                                    <MenuItem value={'นิสิตชั้นปีที่6'}>นิสิตชั้นปีที่6</MenuItem>


                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6}></Grid>
                                        <Grid item xs={12} sm={6}>
                                        <Button variant="contained" color="error" fullWidth onClick={cancle}>
                                            ยกเลิก</Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Button type="submit" variant="contained" color="success" fullWidth >บันทึก</Button>
                                        </Grid>



                                    </Grid>
                                </form>
                            </Paper>
                        </Box>
            
                    </Paper>
                </Container>
            </React.Fragment>
        </div>
    );
}
