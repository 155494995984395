import { useParams, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import api from '../../components/api'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { green } from '@mui/material/colors';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    Container,
    Paper
} from '@mui/material';

const theme = createTheme({
    palette: {
        primary: {
            main: green[300],
        },
        secondary: {
            main: '#4caf50',
        },
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog() {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const [values, setValues] = React.useState({
        password: '',
        showPassword: false,
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const [newpass, setnewpass] = React.useState({
        password: '',
        showPassword: false,
    });

    const handleChangenew = (prop) => (event) => {
        setnewpass({ ...newpass, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
       
    };
    const handleClickShowNewPassword = () => {
        setnewpass({
            ...newpass,
            showPassword: !newpass.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    

    useEffect(() => {
        handleClickOpen()
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        console.log(id)

        api.post("/getDatapharmacist", {

            memb_id: id,
        })
            .then(result => {

                // setPass(result.data[0]['memb_pass'])
                setName(result.data[0]['memb_name'])


            })
            .catch(error => console.log('error', error));


    }, [id])
    const handleSubmit = event => {
        event.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        // const idm = localStorage.getItem("idm");
        console.log(values.password)
        if(newpass.password.length<8){
            alert('รหัสผ่านควรมี8ตัวอักษรขึ้นไป')
        }
        else{
            api.post('/login_pmc', {
                username: id,
                password: values.password
            }).then(response => {
                if (response.data == "Password invalid") { alert('รหัสผ่านไม่ถูกต้อง') }
                else {
                    api.post("/update_pass", {
                        memb_pass: newpass.password,
                        memb_idupdate: id,
                    })
                    .then(result => {
                        alert ('เปลี่ยนรหัสผ่านสำเร็จ')
                        if (result.data['message'] == 'SUCCESS') {
                            navigate("/Pharmacist");
                        }
                    })
                    .catch(error => console.log('error', error));
                }
            })
        }
        
    }
    const [name, setName] = useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        navigate("/Product");
    };


    return (
        <ThemeProvider theme={theme}>
            <div>

                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                ข้อมูลผู้ใช้งาน
                            </Typography>

                        </Toolbar>
                    </AppBar>

                    <Paper sx={{ p: 5, bgcolor: '#c8e6c9' }}>
                        <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
                            <Container maxWidth="lg">
                                <Typography sx={{ mb: 3 }} variant="h4">
                                    รายละเอียด
                                </Typography>
                                <Grid container spacing={3}>
                                    <Grid item lg={4} md={6} xs={12}>
                                        <Card sx={{ border: 2, borderColor: 'primary.main' }}>
                                            <CardContent   >
                                                <Box
                                                    sx={{
                                                        alignItems: 'center',
                                                        display: 'flex',
                                                        flexDirection: 'column',

                                                    }}
                                                >
                                                    <Avatar
                                                        // src={user.avatar}
                                                        sx={{
                                                            height: 64,
                                                            mb: 2,
                                                            width: 64,
                                                            bgcolor: 'green'
                                                        }} />

                                                </Box>
                                                <Box sx={{
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    flexDirection: 'column', color: 'action.active'
                                                }}>
                                                    <Badge color="secondary" variant="dot">
                                                        <Typography
                                                            color="textPrimary"
                                                            gutterBottom
                                                            variant="h5">
                                                            {name}
                                                        </Typography>


                                                    </Badge>
                                                </Box>

                                            </CardContent>
                                            <Divider />
                                        </Card>
                                    </Grid>
                                    <Grid item lg={8} md={6} xs={12}>
                                        <form onSubmit={handleSubmit}>
                                            <Card sx={{ border: 2, borderColor: 'primary.main' }}>
                                                <CardHeader title="เปลี่ยนรหัสผ่านใหม่" />
                                                <Divider />
                                                <CardContent >
                                                    <Grid container spacing={3}>
                                                        <Grid item md={6} xs={12}>
                                                            <FormControl sx={{ m: 1, width: '30ch' }} variant="outlined" helperText="Some important text" >
                                                                <InputLabel htmlFor="outlined-adornment-password"   >รหัสผ่านเดิม</InputLabel>
                                                                <OutlinedInput
                                                                
                                                                    id="outlined-adornment-password"
                                                                    type={values.showPassword ? 'text' : 'password'}
                                                                    value={values.password}
                                                                    onChange={handleChange('password')}
                                                                    
                                                                    endAdornment={
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                aria-label="toggle password visibility"
                                                                                onClick={handleClickShowPassword}
                                                                                onMouseDown={handleMouseDownPassword}
                                                                                edge="end"
                                                                            >
                                                                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    }
                                                                    label="Password"
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item md={6} xs={12}>
                                                        <FormControl sx={{ m: 1, width: '30ch' }} variant="outlined">
                                                                <InputLabel htmlFor="outlined-adornment-password">รหัสผ่านใหม่</InputLabel>
                                                                <OutlinedInput
                                                                    id="outlined-adornment-password"
                                                                    type={newpass.showPassword ? 'text' : 'password'}
                                                                    value={newpass.password}

                                                                    onChange={handleChangenew('password')}
                                                                    endAdornment={
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                aria-label="toggle password visibility"
                                                                                onClick={handleClickShowNewPassword}
                                                                                onMouseDown={handleMouseDownPassword}
                                                                                edge="end"
                                                                            >
                                                                                {newpass.showPassword ? <VisibilityOff /> : <Visibility />}
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    }
                                                                    label="Password"
                                                                />
                                                            </FormControl>
                                                        </Grid>

                                                    </Grid>
                                                </CardContent>
                                                <Divider />
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-end',
                                                        p: 2
                                                    }}
                                                >
                                                    <Button type="submit" color="success" variant="contained">
                                                        บันทึก
                                                    </Button>
                                                </Box>
                                            </Card>
                                        </form>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Box>
                    </Paper>

                </Dialog>
            </div>
        </ThemeProvider>
    );
}
