import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { Grid, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useParams } from 'react-router-dom';
import NavbarAdmin from '../../components/navbar_admin';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { green } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import api from '../../components/api';



export default function Editpharmacist() {
    const { id } = useParams();
    var Link = require('react-router-dom').Link
    const navigate = useNavigate();
    const colorg = green[900];

    const cancle = () => {
        navigate("/Pharmacist");
    };
    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        api.post("/getDatapharmacist",{
            memb_id: id,
        } )
            
            .then(result => {

                setID(result.data[0]['memb_id'])
                setUsername(result.data[0]['memb_username'])
                setPassword(result.data[0]['memb_pass'])
                setName(result.data[0]['memb_name'])
                setEmail(result.data[0]['memb_email'])
                setPhone(result.data[0]['memb_phone'])
                setPosition(result.data[0]['memb_position'])
                setStatus(result.data[0]['memb_status'])

            })
            .catch(error => console.log('error', error));


    }, [id])

    const handleSubmit = event => {
        event.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");


        api.post("/update_pharmacist", {
            memb_id: membid,
            memb_username:username,
            memb_pass: pass,
            memb_name: name,
            memb_email: email,
            memb_phone: phone,
            memb_position: position,
            memb_status: status,
            memb_idupdate: id,

        })
            
            .then(result => {
                alert ('แก้ไขข้อมูลเภสัชกรสำเร็จ')
                if (result.data['message'] == 'SUCCESS') {
                    navigate("/Pharmacist");

                }

            })
            .catch(error => console.log('error', error));

    }
    const [membid, setID] = useState('');
    const [username, setUsername] = useState('');
    const [pass, setPassword] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [position, setPosition] = useState('');
    const [status, setStatus] = React.useState('');


    return (
        <div>
            <NavbarAdmin />,
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="sm" sx={{ p: 10 }}>
                    <Paper sx={{ p: 3, bgcolor: '#c8e6c9' }}>
                        <Typography variant="h6" gutterBottom component="div" color={colorg}>
                            แก้ไขข้อมูลเภสัชกร
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                '& > :not(style)': {
                                    m: 0,
                                    p: 3,
                                    maxWidth: "sm",
                                    maxheight: 'sm',

                                },
                            }}
                        >
                            <Paper variant="outlined" color="secondary" >


                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField id="username" label="รหัสเภสัชกร" variant="outlined"
                                                fullWidth required
                                                onChange={(e) => setUsername(e.target.value)}
                                                value={username} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField id="pass" label="รหัสผ่าน" variant="outlined"
                                                fullWidth required
                                                onChange={(e) => setPassword(e.target.value)}
                                                value={pass} />
                                        </Grid>
                                        <Grid item xs={12} >
                                            <TextField id="name" label="ชื่อ นามสกุล" variant="outlined"
                                                fullWidth required
                                                onChange={(e) => setName(e.target.value)}
                                                value={name} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField id="email" label="Email" variant="outlined"
                                                fullWidth required
                                                onChange={(e) => setEmail(e.target.value)}
                                                value={email} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField id="phone" label="เบอร์ติดต่อ" variant="outlined"
                                                inputProps={{ maxlength:"10",inputMode: 'numeric', pattern:'[0][0-9]*'}} 
                                                fullWidth required
                                                onChange={(e) => setPhone(e.target.value)}
                                                value={phone} />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">ตำแหน่ง</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="position"
                                                    value={position}
                                                    label="ตำแหน่ง"
                                                    onChange={(e) => setPosition(e.target.value)}

                                                >
                                                    <MenuItem value={'อาจารย์'}>อาจารย์</MenuItem>
                                                    <MenuItem value={'นิสิตชั้นปีที่4'}>นิสิตชั้นปีที่4</MenuItem>
                                                    <MenuItem value={'นิสิตชั้นปีที่5'}>นิสิตชั้นปีที่5</MenuItem>
                                                    <MenuItem value={'นิสิตชั้นปีที่6'}>นิสิตชั้นปีที่6</MenuItem>


                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>

                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">สถานะ</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="status"
                                                    value={status}
                                                    label="สถานะ"
                                                    onChange={(e) => setStatus(e.target.value)}

                                                >
                                                    <MenuItem value={'active'}>active</MenuItem>
                                                    <MenuItem value={'inactive'}>inactive</MenuItem>

                                                </Select>
                                            </FormControl>

                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                        <Button variant="contained" color="error" fullWidth onClick={cancle}>
                                    ยกเลิก</Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button type="submit" variant="contained" color="success" fullWidth >บันทึก</Button>
                            </Grid>


                                    </Grid>
                                </form>
                            </Paper>
                        </Box>
                      
                    </Paper>
                </Container>
            </React.Fragment>
        </div>
    );
}
