import React, { useState, useEffect } from "react"
import Navbar from './../../components/navbar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { green } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import api from '../../components/api'




export default function StickyHeadTable() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [items, setItems] = useState([]);
    const [allUser, setAllUser] = useState([]);
    const [searched, setSearched] = React.useState("");
    const navigate = useNavigate();
    var Link = require('react-router-dom').Link
  
    const handleClickOpenDialog = id => {
        navigate("/DetailPharmacist/" + id);
    };


    useEffect(() => {
        PharmacistGet()

    }, [])

    const PharmacistGet = () => {

        api.get("/allpharmacist")

            .then(
                (result) => {

                    // console.log(result.data.memb_id)
                    setItems(result.data);
                    setAllUser(result.data);
                },
            )
    }
    const UpdateUser = id => {
        navigate("/Edit/" + id);

    }
    const Create  =()=> {
        navigate("/Create");

    }

    const requestSearch = (searchedVal) => {
        if (searchedVal == "") {

            setItems(allUser)
        } else {
            setSearched(searchedVal)
            const filteredRows = allUser.filter((user) => {
                return user.memb_name.includes(searchedVal);

            });
            setItems(filteredRows);
        }

    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: green[300],
            color: theme.palette.common.black,
            fontSize: 18,

        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>
            {/* <Navbar /> */}
            <CssBaseline />
            <Container maxWidth="lg" sx={{ p: 10 }}>
                <Paper sx={{ p: 2, bgcolor: '#c8e6c9' }}>
                    <Box display="flex">
                        <Box sx={{ flexGrow: 1 }}>
                            <Typography variant="h4" gutterBottom component="div">
                                รายชื่อเภสัชกร
                            </Typography>
                            <div>
                                <form >
                                    <label >
                                        ระบุชื่อเภสัชกรที่ต้องการค้นหา: &nbsp;
                                        <input type="text" name="name" onChange={e => requestSearch(e.target.value)} />
                                    </label>

                                </form>
                            </div>
                            <br />
                        </Box>

                        <div>{
                            localStorage.getItem("type") === 'admin' ? (
                                <Box>
                                    
                                        <Button onClick={Create} variant="contained" color='success'>+ เพิ่มเภสัชกร</Button>
                                    
                                </Box>

                            ) : ("")
                        }
                        </div>

                    </Box>
                    <TableContainer component={Paper}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow> <StyledTableCell>รหัสเภสัชกร</StyledTableCell>
                                    <StyledTableCell align="center">ชื่อ-นามสกุล</StyledTableCell>
                                    <StyledTableCell align="center">ตำแหน่ง&nbsp;</StyledTableCell>
                                    <StyledTableCell align="center">เบอร์ติดต่อ&nbsp;</StyledTableCell>
                                    <StyledTableCell align="center">สถานะ&nbsp;</StyledTableCell>
                                    <>{
                                        localStorage.getItem("type") === 'admin' ? (
                                            <StyledTableCell align="center">เพิ่มเติม&nbsp;</StyledTableCell>

                                        ) : ("")
                                    }
                                    </>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <TableRow key={row.memb_id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell component="th" scope="row"
                                                    onClick={() => handleClickOpenDialog(row.memb_id)}>{row.memb_username}</TableCell>
                                                <TableCell align="center"
                                                    onClick={() => handleClickOpenDialog(row.memb_id)}>{row.memb_name}</TableCell>
                                                <TableCell align="center"
                                                    onClick={() => handleClickOpenDialog(row.memb_id)}>{row.memb_position}</TableCell>
                                                <TableCell align="center"
                                                    onClick={() => handleClickOpenDialog(row.memb_id)}>{row.memb_phone}</TableCell>
                                                <TableCell align="center"
                                                    onClick={() => handleClickOpenDialog(row.memb_id)}>{row.memb_status}</TableCell>
                                                <>{
                                                    localStorage.getItem("type") === 'admin' ? (
                                                        <TableCell align="center">
                                                            <Button startIcon={<BorderColorIcon />}
                                                                onClick={() => UpdateUser(row.memb_id)}
                                                                color="success" variant="outlined" >แก้ไข</Button>
                                                        </TableCell>

                                                    ) : ("")
                                                }
                                                </>

                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 50, 100]}
                        component="div"
                        count={items.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Container>
        </>
    );
}
