import React from "react"
import { Routes, Route} from "react-router-dom";
import Navbar from '../../components/navbar';
import TableCustomer from './table_customer';

const Contact = () => {
  return (
    <>
    <Navbar/>
    <Routes>
          <Route path="/" element={<TableCustomer />} />
    </Routes>
    </>
  
  )
}
export default Contact