import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { Grid, TextField, Typography } from '@mui/material';
import Navbar from '../../components/navbar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import api from '../../components/api';


export default function CreateCustomer() {
    const navigate = useNavigate();
    const [Idcustomer,setIdcustomer]=useState([]);

    useEffect(() => {
        IDcustomer();
        console.log(IDcustomer) 
    
    }, [])

    const IDcustomer = () => {
        
          api.get("/allcustomer")
            .then(
              (result) => {
                setIdcustomer(result.data);
                console.log(Idcustomer) 
              },
            )
    }

    const cancle =()=>{
        navigate("/Customer");
    };

    const handleSubmit = event => {
        event.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var numbercusId = 0;
        var customerId = "";
        Idcustomer.forEach((id)=>{
            var idsplit =  id.cust_id.split("T");
            if(numbercusId<parseInt(idsplit[1])){
                numbercusId = parseInt(idsplit[1]);
            }
        });
        console.log("numberId"+numbercusId)
        customerId ="CT"+(numbercusId+1).toString();
        console.log("Custid :"+customerId)


        api.post("/insert_customer", {
            cust_id: customerId,
            cust_name: name,
            cust_age: age,
            cust_gender: gender,
            cust_weight: weight,
            cust_phone: phone,
            cust_symptom: symptom,
            cust_allergic: allergic,
            cust_disease: disease,
            cust_medicinelast: medicinelast,

        })
            
            .then(result => {
                alert ('เพิ่มข้อมูลลูกค้าสำเร็จ')
                if (result.data['message'] == 'SUCCESS') {

                    navigate("/Customer");
                }

            })
            .catch(error => console.log('error', error));

    }
    const [custid, setCustid] = useState('');
    const [name, setName] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');
    const [weight, setWeight] = useState('');
    const [phone, setPhone] = useState('');
    const [symptom, setSymptom] = useState('');
    const [allergic, setAllergic] = useState('');
    const [disease, setDisease] = useState('');
    const [medicinelast, setMedicinelast] = useState('');

    return (
        <div>
            {/* <Navbar />, */}
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="sm" sx={{ p: 10 }}>
                <Paper sx={{ p: 3, bgcolor: '#c8e6c9' }}>
                <Typography variant="h6" gutterBottom component="div">
                                เพิ่มข้อมูลลูกค้า
                            </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            '& > :not(style)': {
                                m: 0,
                                p: 3,
                                maxWidth: "sm",
                                maxheight: 'sm',
                                

                            },
                        }}
                    >
                        <Paper variant="outlined" color="secondary" >
                            
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={3}>
                                    {/* <Grid item xs={12} sm={6}>
                                        <TextField id="custid" label="รหัสลูกค้า" variant="outlined"
                                            fullWidth required 
                                            onChange={(e) => setCustid(e.target.value)} />
                                    </Grid> */}

                                    <Grid item xs={12} >
                                        <TextField id="name" label="ชื่อ นามสกุล" variant="outlined"
                                            fullWidth required
                                            onChange={(e) => setName(e.target.value)} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField id="age" label="อายุ" variant="outlined"
                                            inputProps={{inputMode: 'numeric', pattern:'[0-9]*'}}
                                            fullWidth required
                                            onChange={(e) => setAge(e.target.value)} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>

                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">เพศ</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="gender"
                                                value={gender}
                                                label="เพศ"
                                                onChange={(e) => setGender(e.target.value)}

                                            >
                                                <MenuItem value={'ชาย'}>ชาย</MenuItem>
                                                <MenuItem value={'หญิง'}>หญิง</MenuItem>


                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField id="weight" label="น้ำหนัก" variant="outlined"
                                            inputProps={{inputMode: 'numeric', pattern:'[0-9]*'}}
                                            
                                            fullWidth required
                                            onChange={(e) => setWeight(e.target.value)} />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField id="phone" label="เบอร์ติดต่อ" variant="outlined"
                                            inputProps={{ maxlength:"10",inputMode: 'numeric', pattern:'[0][0-9]*'}} 
                                            fullWidth required
                                            onChange={(e) => setPhone(e.target.value)} />
                                    </Grid>

                                    <Grid item xs={12} >
                                        <TextField id="symptom" label="อาการล่าสุด" variant="outlined"
                                            fullWidth required
                                            multiline
                                            rows={3}
                                            onChange={(e) => setSymptom(e.target.value)} />
                                    </Grid>

                                    <Grid item xs={12} >
                                        <TextField id="allergic" label="ประวัติแพ้ยา" variant="outlined"
                                            fullWidth required
                                            multiline
                                            rows={3}
                                            onChange={(e) => setAllergic(e.target.value)} />
                                    </Grid>

                                    <Grid item xs={12} >
                                        <TextField id="disease" label="โรคประจำตัว" variant="outlined"
                                            fullWidth required
                                            multiline
                                            rows={3}
                                            onChange={(e) => setDisease(e.target.value)} />
                                    </Grid>

                                    <Grid item xs={12} >
                                        <TextField id="medicinelast" label="ยาที่รับล่าสุด" variant="outlined"
                                            fullWidth required
                                            multiline
                                            rows={3}
                                            onChange={(e) => setMedicinelast(e.target.value)} />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                <Button variant="contained" color="error" fullWidth onClick={cancle}>ยกเลิก</Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button type="submit" variant="contained" color="success" fullWidth >บันทึก</Button>
                        </Grid>


                                </Grid>
                            </form>
                        </Paper>
                    </Box>

                    </Paper>
                </Container>
            </React.Fragment>
        </div>
    );
}
