import React from 'react'
import { useState,useRef  } from "react";
import { useNavigate } from 'react-router-dom';
import { Link, useParams } from 'react-router-dom';
import { QRCodeCanvas } from "qrcode.react";
import Card from "@mui/material/Card";
import Button from '@mui/material/Button';
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Paper from '@mui/material/Paper';

export default function QrCode() {
    const [url, setUrl] = useState("");
    const { id } = useParams();
    const qrRef = useRef();
    const navigate = useNavigate();

    const product=()=>{
      navigate("/Product");
    };

    const downloadQRCode = (e) => {
      e.preventDefault();
      let canvas = qrRef.current.querySelector("canvas");
      let image = canvas.toDataURL("image/png");
      let anchor = document.createElement("a");
      anchor.href = image;
      anchor.download = 'qr-code'+id+'.png';
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      setUrl("");
    };
  
    const qrCodeEncoder = (e) => {
      setUrl(e.target.value);
    };
  
    const qrcode = (
      <QRCodeCanvas
        id="qrCode"
        value={id}
        size={300}
        bgColor={"#ffffff"}
        level={"H"}
      />
    );
    
    return (
      <>
      <Paper sx={{ p: 10, bgcolor: '#c8e6c9' }}>
      <Card
        sx={{
          maxWidth: 320,
          mx: "auto",
          padding: "1.5rem",
          borderRadius: "4%",
          boxShadow: 15,

        }}
      >
        <br/><h3 align="center">สแกน QR เพื่อดูรายละเอียดสินค้า</h3><br/>
        <div className="qrcode__container">
        <div ref={qrRef}>{qrcode}</div>
         </div>
         <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            align="center"
            fontFamily="Outfit"
            fontWeight="fontWeightBold"
            mt={2}
            mb={2}
            sx={{ color: "#182036" }}
          >
            <div className="input__group">
              <form onSubmit={downloadQRCode}>
                <Button type="submit" variant="outlined" color="success">
                  ดาวน์โหลด QR Code ข้อมูลสินค้า</Button>
              </form>
            </div>
          </Typography>
          <Typography
            variant="body2"
            align="center"
            fontFamily="Outfit"
            fontWeight="fontWeightRegular"
            sx={{ color: "#7b879d" }}
          >
          QR นี้ใช้สำหรับดูรายละเอียดสินค้า <br/>
          <br/> 
          
         
          <Button onClick={product} variant="contained" color="success">เสร็จสิ้น</Button>
          
          </Typography>
         
        </CardContent>
      </Card>
      </Paper>
      </>
    );
}
