import * as React from 'react';
import { styled } from '@mui/material/styles';
import unipharm from './../images/unipharmLogo.png';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Logout from '@mui/icons-material/Logout';
import { Button } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';


import { createTheme ,ThemeProvider} from '@mui/material/styles';
import { green } from '@mui/material/colors';
import { Storefront } from '@mui/icons-material';

const theme = createTheme({
  palette: {
    primary: {
      main: green[100],
    },
    secondary: {
      main: '#212121',
    },
  },
});




export default function Navbar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);

  const logout = () => {
    localStorage.clear();
    window.location.href = '/';
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };



  return (
    <ThemeProvider theme={theme}>
    <Box sx={{ flexGrow: 1 }}>
      <AppBar component="nav">
        <Toolbar>
          
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'none', sm: 'block' } }}
            
          >
             <img src={unipharm} width="300" height="70"/>
        
           
          </Typography>
          
          <Box sx={{ flexGrow: 1 }} />
      
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          
            <Button variant="outlined" endIcon={<LogoutIcon />}
               href="#" onClickCapture={logout} color="error"
               
            >ออกจากระบบ
             
            </Button>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <Button variant="outlined" endIcon={<LogoutIcon />}
             href="#" onClickCapture={logout} color="error"
            >ออกจากระบบ
            
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
 
 
    </Box>
    </ThemeProvider>
  );
}
