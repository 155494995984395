import { Routes, Route} from "react-router-dom";
import React from "react"
import NavbarAdmin from '../../components/navbar_admin';
import Navbar from '../../components/navbar';
import Tablepharmacist from './table_pharmacist';


const About = () => {
  if(localStorage.getItem("type") === 'admin'){
    return (
      <div>
        
        
        <NavbarAdmin />
        
        
        <Routes>
          <Route path="/" element={<Tablepharmacist />} />
  
        </Routes>
  
      </div>
  
  
    )
    
  }
  return (
    <div>
      
      
        <Navbar/>
      
      
      <Routes>
        <Route path="/" element={<Tablepharmacist />} />

      </Routes>

    </div>


  )
}
export default About