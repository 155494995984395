import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import logo from './../../images/logo.png';
import api from '../../components/api'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      <Link color="inherit" href="https://mui.com/">
      </Link>
    </Typography>
  );
}


export default function LoginPage() {

  const [username, setUsername] = React.useState();
  const [password, setPassword] = React.useState();
  const navigate = useNavigate();


  function loginUser(username, password) {

    api.post('/login_pmc', {
      username: username,
      password: password
    }).then(response => { 
      if (response.data == "No data") {
        alert('ไม่มีข้อมูลของผู้ใช้')
      } else if (response.data == "Password invalid") {
        console.log('pass' + response.data)
        alert('รหัสผ่านไม่ถูกต้อง')
      }
      else{
        
        localStorage.setItem('type', response.data[0]['type'])
        localStorage.setItem('idm', response.data[0]['memb_id'])
        localStorage.setItem('pharmacist_name', response.data[0]['memb_name'])
        console.log(response.data)
        if (response.data[0]['memb_id'] != null) {
          if(response.data[0]['memb_status']==='active')
          {
            if(response.data[0]['type'] === 'admin' ){
              
              navigate("/Pharmacist");
            }else {
              localStorage.setItem('page', "/Product")
              navigate("/Product");
            }
          }else{ 
            alert('ข้อมูลของผู้ใช้นี้ไม่สามารถเข้าสู่ระบบได้')
          }
        }
  
      }
  
    }).catch(error => {
      console.log(error);
    });
  
  }

  const handleSubmit = async e => {
    console.log('click');
    
    e.preventDefault();
    loginUser(
      username,
      password
    );
  }

  

  return (
      <Container component="main" maxWidth="xs">
      <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
         <div className='img'>
         <img src={logo} width="150" height="150"/>

         </div>
          <Typography component="h1" variant="h5">
            กรุณาเข้าสู่ระบบ
          </Typography>
          <form className={'classes.form'} onValidate onSubmit={handleSubmit}>

            <TextField 
              color="success"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Username"
              
              name="email"
              autoComplete="email"
              autoFocus
              onChange={e => setUsername(e.target.value)}
            />
            <TextField 
             color="success"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={e => setPassword(e.target.value)}
            />
            <div>  
              
              <Button 
              color="success"
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 5 }}
            >
              เข้าสู่ระบบ
              </Button>
            </div> 
            </form>
          </Box>
        
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>

  );
}