import React, { useState, useEffect } from "react";
import api from './api'
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import unipharm from './../images/unipharmLogo.png';
import PersonIcon from '@mui/icons-material/Person';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { green, grey } from '@mui/material/colors';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { ListItemButton } from "@mui/material";


const Div = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));

const theme = createTheme({
  palette: {
    primary: {
      main: green[100],
    },
    secondary: {
      main: '#212121',
    },
  },
});

export default function MenuAppBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [anchorE3, setAnchorE3] = React.useState(null);
  const open = Boolean(anchorEl);
  const opennoti = Boolean(anchorE2);
  const openapm = Boolean(anchorE3);
  const navigate = useNavigate();


  const customerpage = () => {
    navigate("/Customer");

  }
  const appointmentpage = () => {
    navigate("/Appointment");

  }
  const Pharmacistpage = () => {
    navigate("/Pharmacist");

  }
  const productpage = () => {
    navigate("/Product");

  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

  };
  const handleClick2 = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleClick3 = (event) => {
    setAnchorE3(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorE2(null);
    setAnchorE3(null);
  };

  const Profile = id => {
    navigate("/Profile/" + id);

  }

  const ChangPass = id => {
    navigate("/ChangPass/" + id);

  }

  const logout = () => {
    localStorage.clear();
    window.location.href = '/';
  };

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -3,
      top: 13,
      border: `1px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));

  const [numNoti, setnumNoti] = useState(0);
  const [itemnoti, setItemsnoti] = useState([]);
  const [numNotiApm, setnumNotiApm] = useState(0);
  const [itemnotiApm, setItemsnotiApm] = useState([]);

  useEffect(() => {
    NotiGet();
    NotiApmGet();

  })

  const NotiGet = () => {

    api.get('/getdata_product_join_noti'

    ).then(res => {

      setItemsnoti(
        res.data
      )
      const newList = itemnoti.filter((item) => item.noti_status == "wait")
      setnumNoti(newList.length)
      console.log(numNoti)
    })

  }
  const NotiApmGet = () => {
    api.get('/getdata_apm_join_noti'
    ).then(res => {
      setItemsnotiApm(
        res.data
      )
      const newList = itemnotiApm.filter((itemapm) => itemapm.noti_status == "wait")
      setnumNotiApm(newList.length)
    })

  }


  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>

        <AppBar position="static">
          <Toolbar>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: 'none', sm: 'block' } }}
            >
              <img src={unipharm} width="300" height="70" />

            </Typography>
            <Box sx={{ flexGrow: 1 }} />

            <Button onClick={productpage} color="secondary" >
              <Typography variant="h6" >คลังสินค้า</Typography>
            </Button>
            <Button onClick={Pharmacistpage} color="secondary" >
              <Typography variant="h6" >รายชื่อเภสัชกร</Typography>
            </Button>
            <Button onClick={customerpage} color="secondary">
              <Typography variant="h6" >รายชื่อลูกค้า</Typography>
            </Button>
            <Button onClick={appointmentpage} color="secondary" >
              <Typography variant="h6" >การนัดหมาย</Typography>
            </Button>

            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <IconButton
                size="large"
                aria-label="show new notifications"
                color="inherit"
                onClick={handleClick2}
                aria-controls={opennoti ? 'notification-list' : undefined}
                aria-haspopup="true"
                aria-expanded={opennoti ? 'true' : undefined}
              >
                <Badge badgeContent={numNoti} color="error">
                  <NotificationsIcon color='secondary' />
                </Badge>
              </IconButton>
              <Menu
                id="noti-menu"
                MenuListProps={{
                  'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorE2}
                open={opennoti}
                onClose={handleClose}

                TransitionComponent={Fade}
              ><List sx={{ width: 300, maxWidth: 360, bgcolor: 'background.paper' }} >
                  <Typography variant="h5" gutterBottom component="div" sx={{ p: 2, pb: 0 }}>
                    แจ้งเตือนสินค้า
                  </Typography>
                  {itemnoti.length == 0 && (
                    <Div>{"ไม่มีการแจ้งเตือน"}</Div>
                  )}
                  {itemnoti.map((datanoti) => (
                    <React.Fragment key={datanoti.notipro_id}>
                      {/* {noti_status === "wait" &&( */}
                      {datanoti.noti_status === "wait" && (
                        <ListItemButton sx={{ bgcolor: '#c8e6c9' }} onClick={() => {
                          api.post("/update_statusnoti", {

                            noti_status: "success",
                            notipro_id: datanoti.notipro_id,

                          }).then(error => console.log('error', error));
                          navigate("/DetailsProduct/" + datanoti.notipro_id)
                        }} >
                          <ListItemAvatar>
                            {/* <Avatar alt="Profile Picture" src={person} /> */}
                          </ListItemAvatar>
                          <ListItemText primary={datanoti.product_name + ' ใกล้ถึงวันหมดอายุ'} secondary={'เวลาหมดอายุ ' + datanoti.product_dateExpire} />
                        </ListItemButton>
                      )}
                      {datanoti.noti_status === "success" && (
                        <ListItemButton sx={{ bgcolor: '#eeeeee' }} onClick={() => navigate("/DetailsProduct/" + datanoti.notipro_id)} >
                          <ListItemAvatar>
                            {/* <Avatar alt="Profile Picture" src={person} /> */}
                          </ListItemAvatar>
                          <ListItemText primary={datanoti.product_name + ' ใกล้ถึงวันหมดอายุ'} secondary={'เวลาหมดอายุ ' + datanoti.product_dateExpire} />
                        </ListItemButton>
                      )}


                    </React.Fragment>
                  ))}
                </List></Menu>

              <IconButton
                size="large"
                aria-label="notiappnotifications"
                color="inherit"
                onClick={handleClick3}
                aria-controls={openapm ? 'notification-list' : undefined}
                aria-haspopup="true"
                aria-expanded={openapm ? 'true' : undefined}
              >
                <Badge badgeContent={numNotiApm} color="error">
                  <EventNoteIcon color='secondary' />
                </Badge>
              </IconButton>

              <Menu
                id="noti-menu"
                MenuListProps={{
                  'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorE3}
                open={openapm}
                onClose={handleClose}

                TransitionComponent={Fade}
              ><List sx={{ width: 300, maxWidth: 360, bgcolor: 'background.paper' }} >
                  <Typography variant="h5" gutterBottom component="div" sx={{ p: 2, pb: 0 }}>
                    แจ้งเตือนการนัดหมาย
                  </Typography>
                  {itemnotiApm.length == 0 && (
                    <Div>{"ไม่มีการนัดหมาย"}</Div>
                  )}
                  {itemnotiApm.map((datanoti) => (
                    <React.Fragment key={datanoti.noti_app_id}>
                      {datanoti.noti_status === "wait" && (

                        <ListItemButton sx={{ bgcolor: '#c8e6c9' }}
                          onClick={() => {
                            api.post("/update_statusnotiapm", {

                              noti_status: "success",
                              noti_app_id: datanoti.noti_app_id,

                            }).then(error => console.log('error', error));
                            navigate("/DetailsAppointment/" + datanoti.noti_app_id)
                          }} >

                          <ListItemText primary={'ใบนัดเลขที่ ' + datanoti.noti_app_id + ' ถึงวันนัดหมาย'} secondary={'วันนัดหมาย ' + datanoti.notitime} />
                        </ListItemButton>
                      )}
                      {datanoti.noti_status === "success" && (
                        <ListItemButton sx={{ bgcolor: '#eeeeee' }} onClick={() => navigate("/DetailsAppointment/" + datanoti.noti_app_id)}>
                          <ListItemText primary={'ใบนัดเลขที่ ' + datanoti.noti_app_id + ' ถึงวันนัดหมาย'} secondary={'วันนัดหมาย ' + datanoti.notitime}></ListItemText>
                        </ListItemButton>
                      )}

                    </React.Fragment>
                  ))}

                </List></Menu>
            </Box>

            <div>
              <IconButton
                id="fade-button"
                size="large"
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <AccountCircle color='secondary' />

              </IconButton>
              <Menu
                id="fade-menu"
                MenuListProps={{
                  'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                <MenuItem onClick={() => Profile(localStorage.getItem("idm"))}><PersonIcon fontSize="small" color="success" /> โปรไฟล์</MenuItem>
                <MenuItem onClick={() => ChangPass(localStorage.getItem("idm"))}> <Settings fontSize="small" /> เปลี่ยนรหัสผ่าน</MenuItem>
                <MenuItem href="#" onClickCapture={logout}> <Logout fontSize="small" color="error" /> ออกจากระบบ</MenuItem>
              </Menu>
            </div>

          </Toolbar>
        </AppBar>
      </Box>
    </ThemeProvider>
  );
}
