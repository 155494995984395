import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { Grid, TextField, Typography } from '@mui/material';
import Navbar from '../../components/navbar';
import Button from '@mui/material/Button';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import api from '../../components/api';
import { format } from 'date-fns';




export default function EditProduct() {
    var Link = require('react-router-dom').Link
    const { id } = useParams();
    const navigate = useNavigate();

    const cancle = () => {
        navigate("/Product");
    };
    const updateNoti = () => {
        var date = new Date(expDate);
        date.setDate(date.getDate() - 14)
        console.log(format(date, 'yyyy/MM/dd'))
        api.post("/update_notitime_status", {

            noti_status: "wait",
            notipro_id: id,
            noti_time: format(date, 'yyyy-MM-dd').toLocaleString(),

        }).then(error => console.log('error', error));
    }

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");


        api.post("/getdata_product_join_name", {
            product_id: id,
        })

            .then(result => {
                //console.log(result[0].cust_name)
                setPid(result.data[0]['product_id'])
                setPname(result.data[0]['product_name'])
                setAmount(result.data[0]['product_amount'])
                setInvoice(result.data[0]['product_invoice'])
                setOder(result.data[0]['product_order'])
                setSupplier(result.data[0]['product_supplier'])
                setAddrsSup(result.data[0]['product_addressSuplier'])
                setProducer(result.data[0]['product_producer'])
                setAddrsPro(result.data[0]['product_addressProducer'])
                setAdDate(result.data[0]['product_dateAdd'])
                setExpDate(result.data[0]['product_dateExpire'])
                setStatus(result.data[0]['product_status'])
                setChangeStatus(result.data[0]['product_changeStatus'])
                setUnit(result.data[0]['product_unit'])
                setMemAdd(result.data[0]['memb_name'])



            })
            .catch(error => console.log('error', error));


    }, [id])

    const handleSubmit = event => {
        event.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const idm = localStorage.getItem("idm");

        api.post("/update_product", {
            product_id: pid,
            product_name: pname,
            product_amount: amount,
            product_invoice: invoice,
            product_order: order,
            product_supplier: supplier,
            product_addressSupplier: addrsSup,
            product_producer: producer,
            product_addressProducer: addrsPro,
            product_dateAdd: adDate,
            product_dateExpire: expDate,
            product_status: status,
            product_changeStatus: changeStatus,
            product_unit: unit,
            memb_idp: idm,
            product_idupdate: id,
        })

            .then(result => {
                console.log('test : ', result.status)
                alert('อัพเดทข้อมูลสำเร็จ')
                if (result.status.toString() == '200') {
                    updateNoti();
                    navigate("/Product");
                }

            })
            .catch(error => console.log('error', error));

    }
    const [pid, setPid] = useState('');
    const [pname, setPname] = useState('');
    const [amount, setAmount] = useState('');
    const [invoice, setInvoice] = useState('');
    const [order, setOder] = useState('');
    const [supplier, setSupplier] = useState('');
    const [addrsSup, setAddrsSup] = useState('');
    const [producer, setProducer] = useState('');
    const [addrsPro, setAddrsPro] = useState('');
    const [adDate, setAdDate] = useState('');
    const [expDate, setExpDate] = useState('');
    const [status, setStatus] = useState('');
    const [changeStatus, setChangeStatus] = useState('');
    const [unit, setUnit] = useState('');
    const [memAdd, setMemAdd] = useState('');

    return (
        <div>
            {/* <Navbar />, */}
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="sm" sx={{ p: 10 }}>

                    <Paper sx={{ p: 3, bgcolor: '#c8e6c9' }}>
                        <Typography variant="h6" gutterBottom component="div">
                            แก้ไขข้อมูลสินค้า
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                '& > :not(style)': {
                                    m: 0,
                                    p: 3,
                                    maxWidth: "sm",
                                    maxheight: 'sm',

                                },
                            }}
                        >
                            <Paper variant="outlined" color="secondary" >

                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField id="pid" label="รหัสสินค้า" variant="outlined"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                fullWidth required

                                                value={pid} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField id="pname" label="ชื่อสินค้า" variant="outlined"
                                                fullWidth required
                                                onChange={(e) => setPname(e.target.value)}
                                                value={pname} />

                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField id="amount" label="จำนวนสินค้า" variant="outlined"
                                                fullWidth required
                                                onChange={(e) => setAmount(e.target.value)}
                                                value={amount} />

                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">หน่วยสินค้า</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="unit"
                                                    value={unit}
                                                    label="หน่วยสินค้า"
                                                    onChange={(e) => setUnit(e.target.value)}

                                                >
                                                    <MenuItem value={'ลัง'}>ลัง</MenuItem>
                                                    <MenuItem value={'กระปุก'}>กระปุก</MenuItem>
                                                    <MenuItem value={'ขวด'}>ขวด</MenuItem>
                                                    <MenuItem value={'แผง'}>แผง</MenuItem>
                                                    <MenuItem value={'โหล'}>โหล</MenuItem>
                                                </Select>
                                            </FormControl>

                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField id="invoice" label="เลขที่ใบส่งของ" variant="outlined"
                                                fullWidth required
                                                onChange={(e) => setInvoice(e.target.value)}
                                                value={invoice} />

                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField id="order" label="เลขที่ใบสั่งซื้อ" variant="outlined"
                                                fullWidth required
                                                onChange={(e) => setOder(e.target.value)}
                                                value={order} />

                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField id="supplier" label="ผู้จำหน่าย" variant="outlined"
                                                fullWidth required
                                                onChange={(e) => setSupplier(e.target.value)}
                                                value={supplier} />

                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                id="addrsSup"
                                                label="ที่อยู่ผู้จำหน่าย"
                                                multiline
                                                fullWidth required
                                                rows={3}
                                                onChange={(e) => setAddrsSup(e.target.value)}
                                                value={addrsSup} />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField id="producer" label="ผู้ผลิต" variant="outlined"
                                                fullWidth required
                                                onChange={(e) => setProducer(e.target.value)}
                                                value={producer} />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                id="addrsPro"
                                                label="ที่อยู่ผู้ผลิต"
                                                multiline
                                                fullWidth required
                                                rows={3}
                                                onChange={(e) => setAddrsPro(e.target.value)}
                                                value={addrsPro} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField id="adDate" type="date" variant="outlined"
                                                fullWidth required
                                                helperText="วันที่เพิ่มลงคลัง"
                                                onChange={(e) => setAdDate(e.target.value)}
                                                value={adDate} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField id="expDate" type="date" variant="outlined"
                                                fullWidth required
                                                helperText="วันหมดอายุ"
                                                onChange={(e) => setExpDate(e.target.value)}
                                                value={expDate} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">สถานะ</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="status"
                                                    value={status}
                                                    label="สถานะ"
                                                    onChange={(e) => setStatus(e.target.value)}

                                                >
                                                    <MenuItem value={'คงคลัง'}>คงคลัง</MenuItem>
                                                    <MenuItem value={'ทำลายทิ้ง'}>ทำลายทิ้ง</MenuItem>
                                                    <MenuItem value={'หมดอายุ'}>หมดอายุ</MenuItem>
                                                    <MenuItem value={'เสื่อมสภาพ'}>เสื่อมสภาพ</MenuItem>

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField id="changeStatus" type="date" variant="outlined"
                                                fullWidth required
                                                helperText="วันปรับสถานะ"
                                                onChange={(e) => setChangeStatus(e.target.value)}
                                                value={changeStatus} />
                                        </Grid>
                                        <Grid item xs={12} >
                                            <TextField id="memAdd" label="เภสัชกรผู้บันทึกข้อมูลล่าสุด" variant="outlined"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                fullWidth required
                                                // onChange={(e) => setMemAdd(e.target.value)}
                                                value={memAdd}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>

                                            <Button variant="contained" fullWidth color="error" onClick={cancle}>ยกเลิก</Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button type="submit" variant="contained" fullWidth color="success">บันทึก</Button>
                                        </Grid>

                                    </Grid>
                                </form>
                            </Paper>
                        </Box>


                    </Paper>
                </Container>

            </React.Fragment>
        </div>
    );
}
