import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton'; 
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Grid, TextField } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { green } from '@mui/material/colors';
import api from '../../components/api'


const theme = createTheme({
    palette: {
        primary: {
            main: green[300],
        },
        secondary: {
            main: '#4caf50',
        },
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog() {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        handleClickOpen()
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        console.log(id)
        


      api.post("/getdata_product_join_name", {
     
         product_id: id,
        })
            .then(Response => {
                setPid(Response.data[0]['product_id'])
                setPname(Response.data[0]['product_name'])
                setAmount(Response.data[0]['product_amount'])
                setUnit(Response.data[0]['product_unit'])
                setInvoice(Response.data[0]['product_invoice'])
                setOder(Response.data[0]['product_order'])
                setSupplier(Response.data[0]['product_supplier'])
                setAddrsSup(Response.data[0]['product_addressSuplier'])
                setProducer(Response.data[0]['product_producer'])
                setAddrsPro(Response.data[0]['product_addressProducer'])
                setAdDate(Response.data[0]['product_dateAdd'])
                setExpDate(Response.data[0]['product_dateExpire'])
                setStatus(Response.data[0]['product_status'])
                setChangeStatus(Response.data[0]['product_changeStatus'])
               
                setMemAdd(Response.data[0]['memb_name'])
               
            }).catch(error => console.log('error', error));


    }, [id])

    const handleClickOpen = () => {
        
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        navigate("/Product");
    };
    const QRProduct = id => {
        navigate("/QRCode/"+id);
      }

    const [pid, setPid] = useState('');
    const [pname, setPname] = useState('');
    const [amount, setAmount] = useState('');
    const [unit, setUnit]= useState('');
    const [invoice, setInvoice] = useState('');
    const [order, setOder] = useState('');
    const [supplier, setSupplier] = useState('');
    const [addrsSup, setAddrsSup] = useState('');
    const [producer, setProducer] = useState('');
    const [addrsPro, setAddrsPro] = useState('');
    const [adDate, setAdDate] = useState('');
    const [expDate, setExpDate] = useState('');
    const [status, setStatus] = useState('');
    const [changeStatus, setChangeStatus] = useState('');
    
    const [memAdd, setMemAdd] = useState('');

    return (
        <ThemeProvider theme={theme}>
            <div >
                <Dialog
                    theme
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                คลังสินค้า
                            </Typography>

                        </Toolbar>
                    </AppBar>

                    <React.Fragment>
                        <CssBaseline />

                        <Container maxWidth="sm" sx={{ p: 10 }}  >
                            <Paper sx={{ p: 3, bgcolor: '#c8e6c9' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="h6" gutterBottom component="div">
                                            รายละเอียดสินค้า
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Button startIcon={<QrCodeIcon />} 
                                        onClick={() => QRProduct(id)}
                                        variant="contained" color="primary" >QrCode </Button>
                                    </Grid>
                                </Grid>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        '& > :not(style)': {
                                            m: 0,
                                            p: 3,
                                            // bgcolor: '#c8e6c9' ,
                                            maxWidth: "sm",
                                            maxheight: 'sm',

                                        },
                                    }}
                                >
                                    <Paper variant="outlined" color="secondary" >


                                        <Grid container spacing={2}>
                                            <Grid item xs={12}sm={6}>
                                                <TextField id="pid" label="รหัสสินค้า" variant="standard"
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth required

                                                    value={pid} />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField id="pname" label="ชื่อสินค้า" variant="standard"
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth required

                                                    value={pname} />

                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField id="amount" label="จำนวนสินค้า" variant="standard"
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth required

                                                    value={amount} />

                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField id="unit" label="หน่วยสินค้า" variant="standard"
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth required

                                                    value={unit} />

                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField id="invoice" label="เลขที่ใบส่งของ" variant="standard"
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth required

                                                    value={invoice} />

                                            </Grid>
                                            <Grid item xs={12} sm={6}>

                                                <TextField id="order" label="เลขที่ใบสั่งซื้อ" variant="standard"

                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth required

                                                    value={order} />

                                            </Grid>
                                            <Grid item xs={12} ></Grid>
                                            <Grid item xs={12} >
                                                <TextField id="supplier" label="ผู้จำหน่าย" variant="standard"
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth required

                                                    value={supplier} />

                                            </Grid>
                                            <Grid item xs={12} >

                                                <TextField id="addrsSup" label="ที่อยู่ผู้จำหน่าย" variant="standard"
                                                    multiline
                                                    rows={4}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth required

                                                    value={addrsSup} />
                                            </Grid>
                                            <Grid item xs={12} >
                                                <TextField id="producer" label="ผู้ผลิต" variant="standard"
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth required

                                                    value={producer} />
                                            </Grid>
                                            <Grid item xs={12} >
                                                <TextField id="addrsPro" label="ที่อยู่ผู้ผลิต" variant="standard"
                                                    multiline
                                                    rows={4}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth required

                                                    value={addrsPro} />
                                            </Grid>
                                            <Grid item xs={12} ></Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField id="adDate" label="วันที่เพิ่มลงคลัง" variant="standard"
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth required


                                                    value={adDate} />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField id="expDate" label="วันหมดอายุ" variant="standard"
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth required


                                                    value={expDate} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField id="status" label="สถานะ" variant="standard"
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth required

                                                    value={status} />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField id="changeStatus" label="วันปรับสถานะ" variant="standard"
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth required


                                                    value={changeStatus} />
                                            </Grid>
                     
                                            <Grid item xs={12} sm={6}>
                                                <TextField id="memAdd" label="เภสัชกรผู้บันทึกข้อมูล" variant="standard"
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth required

                                                    value={memAdd} />
                                            </Grid>

                                        </Grid>

                                    </Paper>
                                </Box>

                            </Paper>


                        </Container>

                    </React.Fragment>
                </Dialog>
            </div>
        </ThemeProvider>
    );
}
